






























import Vue, { PropOptions } from 'vue'
import PrimeMultiSelect from 'primevue/multiselect'

import Badge from '@/components/ui/Badge/Badge.vue'

import { objectFromArray } from '@/services/lck-utils/arrays'
import { SelectValue } from '@/services/lck-api/definitions'

export default Vue.extend({
  name: 'LckMultiSelect',
  components: {
    'p-multiselect': Vue.extend(PrimeMultiSelect),
    'lck-badge': Badge,
  },
  props: {
    appendTo: {
      type: String,
      default: 'body',
    },
    options: {
      type: Array,
      default: () => [],
    } as PropOptions<SelectValue[]>,
  },
  computed: {
    optionsObject () {
      return objectFromArray(this.options, 'value')
    },
  },
})
