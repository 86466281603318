







































































import Vue from 'vue'
import Card from 'primevue/card'
import { AuthState, authState } from '@/store/auth'
import { ROUTES_PATH } from '@/router/paths'
import Tag from 'primevue/tag'
import { LckGroup, LckWorkspaceSettings } from '@/services/lck-api/definitions'
import { GROUP_ROLE } from '@locokit/lck-glossary'

interface GroupsByWorkspace extends LckWorkspaceSettings{
   id: string|undefined;
   name: string|undefined;
   groups: {
     id: string;
     name: string;
     role: GROUP_ROLE;
     manager: boolean|undefined;
   }[];
}
export default Vue.extend({
  name: 'Group',
  components: {
    'p-card': Vue.extend(Card),
    'p-tag': Vue.extend(Tag),
  },
  data (): {
    authState: AuthState;
    ROUTES_PATH: typeof ROUTES_PATH;
    } {
    return {
      authState,
      ROUTES_PATH,
    }
  },
  computed: {
    groupsByWorkspace (): GroupsByWorkspace[]|[] {
      if (this.authState.data?.user?.groups && this.authState.data.user.groups.length > 0) {
        return this.authState.data.user.groups.reduce((acc: GroupsByWorkspace[], group: LckGroup) => {
          if (group?.aclset?.workspace) {
            const workspaceId = group.aclset.workspace.id
            const workspaceIndex = acc.findIndex(({ id }) => id === workspaceId)
            if (workspaceIndex > -1) {
              acc[workspaceIndex].groups.push({
                id: group.id,
                name: group.name,
                role: group.uhg_role,
                manager: group?.aclset?.manager,
              })
            } else {
              acc.push({
                id: workspaceId,
                name: group.aclset.workspace.text,
                icon: group.aclset.workspace.settings?.icon,
                color: group.aclset.workspace.settings?.color,
                backgroundColor: group.aclset.workspace.settings?.backgroundColor,
                groups: [
                  {
                    id: group.id,
                    name: group.name,
                    role: group.uhg_role,
                    manager: group.aclset.manager,
                  },
                ],
              })
            }
          }
          return acc
        }, [])
      }
      return []
    },
  },
})
