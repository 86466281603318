











































































































import Vue, { PropOptions } from 'vue'
import Button from 'primevue/button'
import ProcessForm from './ProcessForm.vue'
import { LckProcess, PROCESS_TRIGGER, PROCESS_RUN_STATUS } from '@/services/lck-api/definitions'
import Card from 'primevue/card'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { formatDateString } from '@/services/lck-utils/date'

export default Vue.extend({
  name: 'LckProcess',
  props: {
    process: {
      type: Object,
      required: true,
    } as PropOptions<LckProcess>,
    submitting: {
      type: Boolean,
      default: false,
    },
    /**
     * If Process is given a tableId prop,
     * we'll create process / trigger exclusively on this table
     */
    tableId: {
      type: String,
      required: false,
    },
    suggestionsTable: {
      type: Array,
      required: false,
    },
    suggestionsColumn: {
      type: Array,
      required: false,
    },
  },
  components: {
    'p-button': Vue.extend(Button),
    'p-card': Vue.extend(Card),
    'p-datatable': Vue.extend(DataTable),
    'p-column': Vue.extend(Column),
    'lck-process-form': ProcessForm,
  },
  data () {
    return {
      PROCESS_RUN_STATUS,
      PROCESS_TRIGGER,
      expandedRows: [],
    }
  },
  computed: {
    processTriggerable () {
      if (!this.process) return false
      return (
        this.process.trigger === PROCESS_TRIGGER.MANUAL &&
        !this.process.table_id &&
        this.process.enabled === true
      )
    },
    displayTriggerButton () {
      if (!this.process) return false
      return (
        this.process.trigger === PROCESS_TRIGGER.MANUAL &&
        !this.process.table_id
      )
    },
  },
  methods: {
    formatDateString,
  },
})
