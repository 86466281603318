





























import Vue from 'vue'

import LayoutWithBackground from '@/layouts/WithBackground.vue'
import ResetPassword from '@/components/auth/ResetPassword/ResetPassword.vue'
import { lckClient } from '@/services/lck-api'

export default Vue.extend({
  name: 'ResetPassword',
  data () {
    return {
      loading: false,
      resetOK: false,
      error: null,
    }
  },
  components: {
    'layout-with-background': Vue.extend(LayoutWithBackground),
    'reset-password': Vue.extend(ResetPassword),
  },
  methods: {
    async resetPassword (password: string) {
      this.loading = true
      try {
        await lckClient.service('authManagement').create({
          action: 'resetPwdLong',
          value: {
            token: this.$route.query?.token, // compares to .resetToken
            password, // new password
          },
        })
        this.error = null
        this.resetOK = true
      } catch (err) {
        this.error = err as any
      }
      this.loading = false
    },
  },
})

