
















































import Vue from 'vue'
import Button from 'primevue/button'
import { ValidationObserver } from 'vee-validate'

export default Vue.extend({
  name: 'LckForm',
  components: {
    'p-button': Vue.extend(Button),
    'validation-observer': Vue.extend(ValidationObserver),
  },
  props: {
    submitting: {
      type: Boolean,
      default: false,
    },
    displayCancelButton: {
      type: Boolean,
      default: true,
    },
    displayDeleteButton: {
      type: Boolean,
      default: false,
    },
    fullWidthButton: {
      type: Boolean,
      default: false,
    },
    reset: {
      type: Boolean,
      default: false,
    },
    labelButtonSave: {
      type: String,
      default: '',
    },
  },
  watch: {
    reset (newValue: boolean) {
      if (newValue) {
        (this.$refs['lck-form-record'] as HTMLFormElement).reset()
        this.$emit('reset-form')
      }
    },
  },
})
