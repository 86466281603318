


























import Vue from 'vue'

import { ValidationProvider } from 'vee-validate'

import { LckTableView } from '@/services/lck-api/definitions'

import AutoComplete from '@/components/ui/AutoComplete/AutoComplete.vue'

export default Vue.extend({
  name: 'DataRecordSettingsFields',
  components: {
    'lck-autocomplete': AutoComplete,
    'validation-provider': Vue.extend(ValidationProvider),
  },
  props: {
    id: {
      type: String,
    },
    autocompleteSuggestions: {
      type: Array,
      default: () => ([]),
    } as Vue.PropOptions<{ label: string; value: string }[]>,
    tableViewDefinition: {
      type: Object as Vue.PropType<LckTableView | null>,
    },
  },
  data (): {
    tableView: { text: string; value: string } | null;
    } {
    return {
      tableView: null,
    }
  },
  watch: {
    tableViewDefinition: {
      handler (view: LckTableView | null) {
        if (view) {
          this.tableView = {
            value: view.id,
            text: view.text,
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    onChangeTableView () {
      this.$emit('update:id', this.tableView?.value)
      this.$emit('component-refresh-required', true)
    },
  },
})
