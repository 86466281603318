var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p-button',{class:_vm.error
    ? 'p-button-outlined p-button-danger'
    : _vm.disabled
      ? 'p-button-outlined p-disabled'
      : _vm.classes,attrs:{"icon":_vm.loading
    ? 'pi pi-spin pi-spinner'
    : _vm.error
      ? 'pi pi-exclamation-circle'
      : ("" + _vm.icon),"disabled":_vm.disabled || _vm.loading,"title":_vm.loading
    ? _vm.$t('common.loading')
    : _vm.error || _vm.title},on:{"click":function($event){return _vm.$emit('click')}}})}
var staticRenderFns = []

export { render, staticRenderFns }