



















































































































































import Vue from 'vue'

import { ValidationProvider } from 'vee-validate'
import cloneDeep from 'lodash/cloneDeep'
import { COLUMN_TYPE } from '@locokit/lck-glossary'

import {
  LckTableViewColumn,
  SelectValue,
  SelectValueWithId,
} from '@/services/lck-api/definitions'

import Checkbox from 'primevue/checkbox'
import InputText from 'primevue/inputtext'
import Textarea from 'primevue/textarea'

import LckForm from '@/components/ui/Form/Form.vue'
import ColumnValidation from '@/components/admin/database/ColumnValidation/ColumnValidation.vue'
import SelectTypeColumn from '@/components/admin/database/SelectTypeColumn/SelectTypeColumn.vue'

export default Vue.extend({
  name: 'ColumnForm',
  components: {
    'lck-form': LckForm,
    'lck-select-type-column': SelectTypeColumn,
    'p-input-text': Vue.extend(InputText),
    'p-checkbox': Vue.extend(Checkbox),
    'p-textarea': Vue.extend(Textarea),
    'lck-column-validation': ColumnValidation,
    'validation-provider': Vue.extend(ValidationProvider),
  },
  props: {
    column: {
      type: Object,
      default: () => ({}),
    },
    submitting: {
      type: Boolean,
      default: false,
    },
  },
  data (): {
    columnCopy: LckTableViewColumn;
    } {
    return {
      columnCopy: new LckTableViewColumn(),
    }
  },
  computed: {
    isSelectColumnType (): boolean {
      return (
        this.columnCopy.column_type_id === COLUMN_TYPE.SINGLE_SELECT ||
        this.columnCopy.column_type_id === COLUMN_TYPE.MULTI_SELECT
      )
    },
  },
  watch: {
    column: {
      handler (newColumnValue: LckTableViewColumn) {
        this.columnCopy = cloneDeep(newColumnValue)
        if (!this.columnCopy.settings) {
          this.$set(this.columnCopy, 'settings', {})
        }
        if (!this.columnCopy.validation) {
          this.$set(this.columnCopy, 'validation', {})
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    selectTypeValuesChange (data: SelectValueWithId[]) {
      const selectTypeValues: Record<string, SelectValue> = {}
      data.forEach(({ id, label, color, backgroundColor, position, value }: SelectValueWithId) => {
        selectTypeValues[id] = { label, color, backgroundColor, position, value }
      })
      this.columnCopy.settings.values = selectTypeValues
    },
    defaultSelectTypeValueIdChange (defaultValue: string) {
      this.columnCopy.settings.default = defaultValue
    },
    submitColumnData () {
      const editedColumn: { text: string; settings?: object; validation?: object; documentation: string | undefined} = {
        text: this.columnCopy.text,
        validation: this.columnCopy.validation,
        documentation: this.columnCopy.documentation,
      }
      if (this.isSelectColumnType) {
        editedColumn.settings = this.columnCopy.settings
      }
      this.$emit('column-edit', editedColumn)
    },
    submitTableViewColumnData () {
      this.$emit('table-view-column-edit', {
        displayed: this.columnCopy.displayed,
        editable: this.columnCopy.editable,
        transmitted: this.columnCopy.transmitted,
      })
    },
  },
})
