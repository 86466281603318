

































































































































import Vue from 'vue'

import { ValidationProvider } from 'vee-validate'

import { COLUMN_TYPE } from '@locokit/lck-glossary'

import { lckServices } from '@/services/lck-api'

import Button from 'primevue/button'
import Sidebar from 'primevue/sidebar'
import InputText from 'primevue/inputtext'
import Textarea from 'primevue/textarea'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'

import LckForm from '@/components/ui/Form/Form.vue'
import { TranslateResult } from 'vue-i18n'
import { LckTable, LckTableColumn } from '@/services/lck-api/definitions'
import { transformColumnsType } from '@/services/lck-utils/temporary'

export default Vue.extend({
  name: 'UpdateTableSidebar',
  components: {
    'lck-form': LckForm,
    'handle-column-modal': () => import(/* webpackChunkName: "lck-sidebar-schema-monaco-editor" */'@/views/modals/HandleColumnModal.vue'),
    'p-button': Vue.extend(Button),
    'p-sidebar': Vue.extend(Sidebar),
    'p-input-text': Vue.extend(InputText),
    'p-textarea': Vue.extend(Textarea),
    'p-datatable': Vue.extend(DataTable),
    'p-column': Vue.extend(Column),
    'validation-provider': Vue.extend(ValidationProvider),
  },
  props: {
    databaseId: String,
    currentTable: {
      type: Object,
      default: () => ({}),
    },
    showUpdateTableSidebar: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      showHandleColumnModal: false,
      columnToHandle: null as LckTableColumn|null,
    }
  },
  computed: {
    currentTableToUpdate (): LckTable {
      return JSON.parse(JSON.stringify(this.currentTable))
    },
    columnTypes (): {id: number; name: string|TranslateResult}[] {
      return Object.keys(transformColumnsType()).map((key: string) => {
        return ({
          id: COLUMN_TYPE[key as keyof typeof COLUMN_TYPE],
          name: this.$t(`pages.databaseSchema.columnType.${key}.name`),
        })
      })
    },
  },
  methods: {
    async updateTableName () {
      try {
        await lckServices.table.patch(this.currentTable.id, {
          text: this.currentTableToUpdate.text,
          documentation: this.currentTableToUpdate.documentation,
          slug: this.currentTableToUpdate.slug,
        } as LckTable)
        this.$emit('reload-tables')
      } catch (errorUpdateTable) {
        if (errorUpdateTable instanceof Error) console.error(errorUpdateTable.message)
      }
    },
    createColumn () {
      this.showHandleColumnModal = true
    },
    updateColumn (column: LckTableColumn|null) {
      this.columnToHandle = column
      this.showHandleColumnModal = true
    },
    onCloseHandleColumnModal (shouldReloadTable: boolean) {
      if (shouldReloadTable) {
        this.$emit('reload-tables')
      }
      this.columnToHandle = null
      this.showHandleColumnModal = false
    },
    getColumnType (columnTypeId: number) {
      return this.columnTypes.find((columnType) => columnType.id === columnTypeId)?.name
    },
  },
})
