





















































import Vue from 'vue'
import Dialog from 'primevue/dialog'
import Button from 'primevue/button'
import { ValidationObserver } from 'vee-validate'

export default {
  name: 'LckDialogForm',
  components: {
    'p-dialog': Vue.extend(Dialog),
    'p-button': Vue.extend(Button),
    'validation-observer': Vue.extend(ValidationObserver),
  },
  props: {
    header: {
      type: String,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    submitting: {
      type: Boolean,
      default: false,
    },
    contentStyle: {
      type: Object,
    },
    confirmationDialog: {
      type: Boolean,
      default: false,
    },
  },
}
