











































































import Vue from 'vue'
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'
import Password from 'primevue/password'
import { regexPasswordRules } from '@/services/lck-utils/regex'

export default Vue.extend({
  name: 'LckResetPassword',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Error,
      default: null,
    },
    reset: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      password: '',
      passwordCheck: '',
      displayErrorMismatch: false,
      regexPasswordRules,
    }
  },
  components: {
    'p-input-text': Vue.extend(InputText),
    'p-password': Vue.extend(Password),
    'p-button': Vue.extend(Button),
  },
  methods: {
    emitSubmit () {
      this.checkPasswordMismatch()
      if (this.displayErrorMismatch) return
      this.$emit('submit', this.password)
    },
    // Check if mismatch between the password input
    checkPasswordMismatch () {
      if (this.password && this.passwordCheck) {
        this.displayErrorMismatch = (this.password !== this.passwordCheck)
      }

      if (!this.password && !this.passwordCheck) {
        this.displayErrorMismatch = false
      }
    },
  },
})
