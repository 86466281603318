




























import Vue from 'vue'

import { lckClient } from '@/services/lck-api'
import { LckSignUp } from '@/services/lck-api/definitions'
import { ROUTES_NAMES } from '@/router/paths'

import { appState } from '@/store/app'

import SignUp from '@/components/auth/SignUp/SignUp.vue'
import LayoutWithBackground from '@/layouts/WithBackground.vue'

export default Vue.extend({
  name: 'SignUp',
  data () {
    return {
      appState,
      error: null as Error | null,
      loading: false,
      signUpOk: false,
      ROUTES_NAMES,
    }
  },
  components: {
    'layout-with-background': Vue.extend(LayoutWithBackground),
    'sign-up': SignUp,
  },
  methods: {
    async signUp (credentials: Partial<LckSignUp>) {
      if (appState.allowSignUp) {
        this.loading = true
        try {
          await lckClient.service('signup').create(credentials)
          this.signUpOk = true
          this.error = null
        } catch (err) {
          this.error = err as Error
        } finally {
          this.loading = false
        }
      } else {
        this.$toast.add({
          severity: 'error',
          summary: this.$t('error.impossibleOperation'),
          detail: this.$t('pages.signup.forbid'),
          life: 5000,
        })
      }
    },
  },
})

