





































































import LckAsyncImage from '@/components/ui/AsyncImage/AsyncImage.vue'
import { LckAttachment } from '@/services/lck-api/definitions'
import Button from 'primevue/button'
import Vue, { PropType } from 'vue'
import { getAttachmentsToDisplay } from './helpers'

export default Vue.extend({
  name: 'LckFileInput',
  components: {
    'lck-async-image': LckAsyncImage,
    'p-button': Vue.extend(Button),
  },
  props: {
    attachments: {
      type: Array as PropType<LckAttachment[]>,
      default: () => [],
    },
    workspaceId: {
      type: String,
      default: '',
    },
    displayLabels: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    attachmentsToDisplay () {
      return getAttachmentsToDisplay(this.attachments, this.workspaceId)
    },
  },
  methods: {
    onDialogInput () {
      this.$emit('input', (this.$refs['input-file'] as HTMLInputElement).files)
    },
  },
  watch: {
    attachments: {
      handler (newAttachments: LckAttachment[]) {
        this.$emit('updated-attachments', newAttachments)
      },
    },
  },
})
