











































































































































































/* eslint-disable @typescript-eslint/camelcase */

import Vue, { PropOptions } from 'vue'

import { ValidationProvider } from 'vee-validate'

import {
  LckProcess,
  PROCESS_TRIGGER,
} from '@/services/lck-api/definitions'

import InputText from 'primevue/inputtext'
import Dropdown from 'primevue/dropdown'
import InputSwitch from 'primevue/inputswitch'
import InputNumber from 'primevue/inputnumber'

import Form from '@/components/ui/Form/Form.vue'
import AutoComplete from '@/components/ui/AutoComplete/AutoComplete.vue'

export default Vue.extend({
  name: 'LckProcessForm',
  components: {
    'lck-autocomplete': AutoComplete,
    'lck-form': Form,
    'p-dropdown': Vue.extend(Dropdown),
    'p-input-number': Vue.extend(InputNumber),
    'p-input-text': Vue.extend(InputText),
    'p-input-switch': Vue.extend(InputSwitch),
    'validation-provider': Vue.extend(ValidationProvider),
  },
  props: {
    process: {
      type: Object,
    } as PropOptions<LckProcess>,
    tables: {
      type: Array,
      default: () => ([]),
    } as PropOptions<{ label: string; value: string }[]>,
    columns: {
      type: Array,
      default: () => ([]),
    } as PropOptions<{ label: string; value: string }[]>,
    submitting: {
      type: Boolean,
      default: false,
    },
  },
  data (): {
    processCloned: LckProcess;
    triggerOptions: { label: PROCESS_TRIGGER; value: PROCESS_TRIGGER }[];
    } {
    return {
      processCloned: new LckProcess(),
      triggerOptions: [{
        label: PROCESS_TRIGGER.CREATE_ROW,
        value: PROCESS_TRIGGER.CREATE_ROW,
      }, {
        label: PROCESS_TRIGGER.UPDATE_ROW,
        value: PROCESS_TRIGGER.UPDATE_ROW,
      }, {
        label: PROCESS_TRIGGER.UPDATE_ROW_DATA,
        value: PROCESS_TRIGGER.UPDATE_ROW_DATA,
      }, {
        label: PROCESS_TRIGGER.MANUAL,
        value: PROCESS_TRIGGER.MANUAL,
      }],
    }
  },
  computed: {
    triggerWithSettings (): boolean {
      return this.processCloned.trigger === PROCESS_TRIGGER.UPDATE_ROW_DATA
    },
  },
  watch: {
    process: {
      handler (newValue: LckProcess) {
        if (!newValue) return
        const {
          id,
          text,
          trigger,
          settings,
          enabled,
          url,
          table_id,
          table,
          maximumNumberSuccess,
          workspace_id,
        } = newValue
        this.processCloned = { id, text, trigger, enabled: !!enabled, url, table_id, settings, table, maximumNumberSuccess: maximumNumberSuccess || 0, workspace_id }
        if (settings?.column) {
          this.processCloned.settings = {
            ...settings,
            column: { ...settings.column },
          }
        }
      },
      immediate: true,
    },
  },
})
