<template>
  <div class="text-gray-600">
    <p>{{$t('error.basic')}}</p>
  </div>
</template>

<script>
export default {
  name: 'LckError',
}
</script>
