







































































































































































































































import Vue from 'vue'
import { LckGroup, LckUser, LckUserGroup } from '@/services/lck-api/definitions'
import { ValidationProvider } from 'vee-validate'
import { GROUP_ROLE } from '@locokit/lck-glossary'

import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Dropdown from 'primevue/dropdown'
import Button from 'primevue/button'
import Dialog from 'primevue/dialog'
import Checkbox from 'primevue/checkbox'
import Card from 'primevue/card'
import InputText from 'primevue/inputtext'

import Form from '@/components/ui/Form/Form.vue'
import AutoComplete from '@/components/ui/AutoComplete/AutoComplete.vue'
import DialogForm from '@/components/ui/DialogForm/DialogForm.vue'

const defaultUsergroup: {
  userId?: string;
  userName?: string;
  userIsVerified?: boolean;
  groupId?: string;
  groupName?: string;
  role: GROUP_ROLE;
} = {
  role: GROUP_ROLE.MEMBER,
}

export default Vue.extend({
  name: 'WorkspaceAdminUserGroupForm',
  components: {
    'lck-autocomplete': AutoComplete,
    'lck-form': Form,
    'lck-dialog-form': DialogForm,
    'p-card': Vue.extend(Card),
    'p-datatable': Vue.extend(DataTable),
    'p-column': Vue.extend(Column),
    'p-button': Vue.extend(Button),
    'p-checkbox': Vue.extend(Checkbox),
    'p-dropdown': Vue.extend(Dropdown),
    'p-dialog': Vue.extend(Dialog),
    'p-input-text': Vue.extend(InputText),
    'validation-provider': Vue.extend(ValidationProvider),
  },
  props: {
    group: {
      type: Object,
      required: true,
    } as Vue.PropOptions<LckGroup>,
    submitting: {
      type: Boolean,
    },
    aclSetSuggestions: {
      type: Array,
      required: false,
      default () { return [] },
    },
    userSuggestions: {
      type: Array,
      required: false,
      default () { return [] },
    },
  },
  data: function () {
    return {
      groupCloned: new LckGroup(),
      openDialog: false,
      openConfirmation: false,
      isEditingUser: false,
      usergroup: {
        ...defaultUsergroup,
      },
      allRoles: Object.keys(GROUP_ROLE).map(key => ({ label: key, value: key })),
    }
  },
  methods: {
    hideDialog () {
      this.openDialog = false
    },
    hideConfirmation () {
      this.openConfirmation = false
    },
    addNewUserInGroup (groupId: string, groupName: string) {
      this.isEditingUser = false
      this.openDialog = true
      this.usergroup = {
        ...defaultUsergroup,
        groupId,
        groupName,
      }
    },
    editUserInGroup (data: LckUser & LckUserGroup, group: LckGroup) {
      this.isEditingUser = true
      this.openDialog = true
      this.usergroup = {
        ...defaultUsergroup,
        userId: data.id,
        userName: data.name,
        userIsVerified: data.isVerified,
        groupId: group.id,
        groupName: group.name,
        role: data.uhg_role,
      }
    },
    deleteUserInGroup (data: LckUser & LckUserGroup, group: LckGroup) {
      this.openConfirmation = true
      this.usergroup = {
        ...defaultUsergroup,
        userId: data.id,
        userName: data.name,
        groupId: group.id,
        groupName: group.name,
        role: data.uhg_role,
      }
    },
    async confirmDeleteUserInGroup () {
      this.$emit('delete-usergroup', this.usergroup.userId, this.usergroup.groupId)
      this.hideConfirmation()
    },
    async submitUser () {
      if (this.isEditingUser) {
        this.$emit('update-usergroup', this.usergroup.userId, this.usergroup.groupId, this.usergroup.role)
      } else {
        this.$emit('create-usergroup', this.usergroup.userId, this.usergroup.groupId, this.usergroup.role)
      }
      this.hideDialog()
    },
  },
  watch: {
    group: {
      handler (newValue: LckGroup) {
        if (!newValue) return
        this.groupCloned = {
          id: newValue.id,
          name: newValue.name,
          // eslint-disable-next-line @typescript-eslint/camelcase
          uhg_role: newValue.uhg_role,
          // eslint-disable-next-line @typescript-eslint/camelcase
          aclset_id: newValue.aclset_id,
          aclset: undefined,
        }
        if (newValue.aclset) {
          this.groupCloned.aclset = { ...newValue.aclset }
        }
      },
      immediate: true,
    },
  },
})
