








import Vue, { PropType } from 'vue'

import { marked } from 'marked'

import { MarkdownSettings } from '@locokit/lck-glossary'

export default Vue.extend({
  name: 'Markdown',
  props: {
    settings: {
      type: Object as PropType<MarkdownSettings>,
      default: () => ({
        content: '',
      }),
    },
  },
  computed: {
    markdownToDisplay (): string {
      return marked.parse(this.settings.content || '')
    },
  },
})
