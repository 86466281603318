































































































































































































/* eslint-disable @typescript-eslint/camelcase */
// @ts-nocheck

import Vue from 'vue'
import draggable from 'vuedraggable'

import CommonCMSPage from '../../../common/cms/CommonCMSPage.vue'
import { ROUTES_NAMES } from '@/router/paths'

import Button from 'primevue/button'

import UpdateSidebar from '@/components/visualize/UpdateSidebar/UpdateSidebar.vue'
import NavAnchorLink from '@/components/ui/NavAnchorLink/NavAnchorLink.vue'

import { lckServices } from '@/services/lck-api'
import {
  LckBlockSource,
  LckBlockGeoSource,
  LckBlockSecondarySource,
  LckPage,
  LckContainer,
  LckBlockExtended,
} from '@/services/lck-api/definitions'

export default Vue.extend({
  name: 'WorkspaceAdminCMSPage',
  extends: CommonCMSPage,
  components: {
    'update-sidebar': UpdateSidebar,
    'lck-nav-anchor-link': NavAnchorLink,
    'p-button': Button,
    draggable: Vue.extend(draggable),
  },
  props: {
    groupId: {
      type: String,
      required: false,
    },
    userId: {
      type: Number,
      required: false,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  data (): {
    page: LckPage;
    sources: Record<string, LckBlockSource>;
    blocksOptions: object;
    autocompleteSuggestions: null;
    exporting: boolean;
    cellState: {
      columnId?: string;
      rowId?: string;
      isValid?: boolean;
      waiting?: boolean;
    };
    showUpdateSidebar: boolean;
    currentContainerToEdit: LckContainer | null;
    currentContainerToDelete: LckContainer | null;
    currentBlockToEdit: LckBlockExtended | null;
    currentBlockToDelete: LckBlockExtended | null;
    submitting: boolean;
    editableSidebarWidth: string;
    editableAutocompleteSuggestions: object | null;
    blockDisplayTableViewSuggestions: object | null;
    blockDisplayFieldSuggestions: object | null;
    geoSources: Record<string, LckBlockGeoSource>;
    secondarySources: Record<string, LckBlockSecondarySource>;
    } {
    return {
      page: new LckPage(),
      sources: {},
      blocksOptions: {},
      autocompleteSuggestions: null,
      exporting: false,
      cellState: {},
      showUpdateSidebar: false,
      currentContainerToEdit: null,
      currentContainerToDelete: null,
      currentBlockToEdit: null,
      currentBlockToDelete: null,
      submitting: false,
      editableSidebarWidth: '30rem',
      editableAutocompleteSuggestions: null,
      blockDisplayTableViewSuggestions: null,
      blockDisplayFieldSuggestions: null,
      geoSources: {},
      secondarySources: {},
    }
  },
  computed: {
    routeNamePage (): string {
      return ROUTES_NAMES.WORKSPACE_ADMIN.CMS_PAGE
    },
    routeNamePageDetail (): string {
      return ROUTES_NAMES.WORKSPACE_ADMIN.CMS_PAGE_DETAIL
    },
  },
  methods: {
    /**
     * Edition part
     */
    async updateModeNavigation (modeNavigation: string) {
      try {
        this.submitting = true
        await lckServices.page.patch(this.page.id, { modeNavigation })
        this.page.modeNavigation = modeNavigation
        this.$toast.add({
          severity: 'success',
          summary: this.$t('components.processPanel.SUCCESS'),
          detail: this.$t('success.updated'),
          life: 5000,
        })
      } catch (error) {
        this.displayToastOnError(`${this.$t('pages.workspace.page')} ${this.page.text}`, error)
      } finally {
        this.submitting = false
      }
    },
    onContainerEditClick (containerToEdit: LckContainer) {
      this.currentContainerToEdit = containerToEdit?.id ? containerToEdit : null
      this.currentBlockToEdit = null
      this.showUpdateSidebar = true
    },
    onNavAnchorLinkEditClick () {
      this.currentContainerToEdit = null
      this.currentBlockToEdit = null
      this.showUpdateSidebar = true
    },
    async onContainerEditInput (containerToEdit: LckContainer) {
      try {
        this.submitting = true
        const { id, ...data } = containerToEdit
        if (id !== 'temp') {
          // On update
          const updatedContainer = await lckServices.container.patch(id, data)
          for (const key in updatedContainer) {
            this.currentContainerToEdit[key] = updatedContainer[key]
          }
        } else {
          // On create
          this.currentContainerToEdit = await lckServices.container.create({
            ...data,
            page_id: this.page.id,
          })
          this.page.containers.push(this.currentContainerToEdit)
        }
      } catch (error) {
        this.displayToastOnError(`${this.$t('pages.workspace.container')} ${containerToEdit.text}`, error)
      } finally {
        this.submitting = false
      }
    },
    onContainerDeleteClick (containerToDelete: LckContainer) {
      this.currentContainerToDelete = containerToDelete
      this.$confirm.require({
        message: `${this.$t('form.specificDeleteConfirmation')} ${containerToDelete.text}`,
        header: this.$t('form.confirmation'),
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          try {
            await this.onContainerDeleteInput(containerToDelete)
            this.$toast.add({
              severity: 'success',
              summary: this.$t('components.processPanel.SUCCESS'),
              detail: this.$t('success.removed'),
              life: 5000,
            })
          } catch (error) {
            this.displayToastOnError(`${this.$t('pages.workspace.container.title')} ${containerToDelete.text}`, error)
          }
        },
      })
    },
    async onContainerDeleteInput (containerToDelete: LckContainer) {
      if (containerToDelete?.id) {
        await lckServices.container.remove(containerToDelete.id)
        const containerIndex = this.page.containers.findIndex(container => container.id === containerToDelete.id)
        if (containerIndex >= 0) this.page.containers.splice(containerIndex, 1)
      }
      if (containerToDelete?.id === this.currentContainerToEdit?.id) {
        this.onCloseUpdateContainerSidebar()
      }
    },
    async onContainerReorderClick ({ moved }: { moved: { oldIndex: number; newIndex: number }}) {
      if (moved) {
        const minIndex = Math.min(moved.oldIndex, moved.newIndex)
        const maxIndex = Math.max(moved.oldIndex, moved.newIndex)

        const updatedContainerPromises = []
        for (let index = minIndex; index <= maxIndex; index++) {
          updatedContainerPromises.push(
            lckServices.container.patch(this.page.containers[index].id, { position: index }),
          )
        }
        await Promise.all(updatedContainerPromises)
          .catch(error => {
            this.displayToastOnError(`${this.$t('pages.workspace.page')} ${this.page.text}`, error)
          })
      }
    },
    onCloseUpdateContainerSidebar () {
      this.currentContainerToEdit = null
      this.currentBlockToEdit = null
      this.showUpdateSidebar = false
    },
    onBlockEditClickFromSidebar (blockToEdit: LckBlockExtended) {
      this.currentBlockToEdit = {
        ...blockToEdit,
        definition: this.getBlockDefinition(blockToEdit),
      }
    },
    onContainerEditClickFromSidebar (containerToEdit: LckContainer) {
      this.currentContainerToEdit = containerToEdit
    },
    onBlockEditClick (containerToEdit: LckContainer, blockToEdit: LckBlockExtended) {
      this.currentContainerToEdit = containerToEdit
      this.currentBlockToEdit = {
        ...blockToEdit,
      }
      const currentBlockDefinition = this.getBlockDefinition(blockToEdit)
      if (currentBlockDefinition) this.currentBlockToEdit.definition = currentBlockDefinition
      this.showUpdateSidebar = true
    },
    async onBlockEditInput ({ blockToEdit, blockRefreshRequired }) {
      try {
        this.submitting = true
        const { id, ...data } = blockToEdit
        if (id !== 'temp') {
          // On update
          const updatedBlock = await lckServices.block.patch(id, data)
          // Update the existing block in page>container>block with its new properties
          const currentBlock = this.page.containers.find(c => c.id === updatedBlock.containerId).blocks.find(b => b.id === updatedBlock.id)
          for (const key in updatedBlock) {
            currentBlock[key] = updatedBlock[key]
          }
        } else {
          // On create
          this.currentBlockToEdit = await lckServices.block.create({
            ...data,
            container_id: this.currentContainerToEdit.id,
          })
          // Add the block to the related container
          if (Array.isArray(this.currentContainerToEdit.blocks)) {
            this.currentContainerToEdit.blocks.push(this.currentBlockToEdit)
          } else {
            this.$set(this.currentContainerToEdit, 'blocks', [this.currentBlockToEdit])
          }
        }
        // Load the block definition and content if it is necessary
        if (blockRefreshRequired) await this.refreshDefinitionAndContent(this.currentBlockToEdit)
      } catch (error) {
        this.displayToastOnError(`${this.$t('pages.workspace.block.title')} ${this.currentBlockToEdit.title}`, error)
      } finally {
        this.submitting = false
      }
    },
    onBlockDeleteClick (containerToEdit: LckContainer, blockToDelete: LckBlockExtended) {
      this.currentContainerToDelete = containerToEdit
      this.currentBlockToDelete = blockToDelete
      this.$confirm.require({
        message: `${this.$t('form.specificDeleteConfirmation')} ${blockToDelete.title}`,
        header: this.$t('form.confirmation'),
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          try {
            await this.onBlockDeleteInput(blockToDelete)
            this.$toast.add({
              severity: 'success',
              summary: this.$t('components.processPanel.SUCCESS'),
              detail: this.$t('success.removed'),
              life: 5000,
            })
          } catch (error) {
            this.displayToastOnError(`${this.$t('pages.workspace.block.title')} ${blockToDelete.title}`, error)
          }
        },
      })
    },
    async onBlockDeleteInput (blockToDelete: LckBlockExtended) {
      if (blockToDelete.id) {
        await lckServices.block.remove(blockToDelete.id)
        const blockToDeleteIndex = this.currentContainerToDelete?.blocks.findIndex(block => block.id === blockToDelete.id)
        if (blockToDeleteIndex && blockToDeleteIndex >= 0) {
          (this.currentContainerToDelete as LckContainer).blocks.splice(blockToDeleteIndex, 1)
        }
        if (blockToDelete.id === this.currentBlockToEdit?.id) {
          this.currentBlockToEdit = null
        }
      }
    },
    async onBlockReorderClick (
      container: LckContainer,
      { moved }: { moved: { oldIndex: number; newIndex: number}},
    ) {
      if (moved) {
        const minIndex = Math.min(moved.oldIndex, moved.newIndex)
        const maxIndex = Math.max(moved.oldIndex, moved.newIndex)

        const updatedBlockPromises = []
        for (let index = minIndex; index <= maxIndex; index++) {
          updatedBlockPromises.push(
            lckServices.block.patch(container.blocks[index].id, { position: index }),
          )
        }
        await Promise.all(updatedBlockPromises)
          .catch(error => {
            this.displayToastOnError(`${this.$t('pages.workspace.container')} ${container.text}`, error)
          })
      }
    },
  },
})
