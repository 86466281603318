































import Vue from 'vue'

import { ValidationProvider } from 'vee-validate'

import InputText from 'primevue/inputtext'

import LckDialogForm from '@/components/ui/DialogForm/DialogForm.vue'

export default Vue.extend({
  name: 'ChapterDialog',
  components: {
    'lck-dialog-form': LckDialogForm,
    'p-input-text': Vue.extend(InputText),
    'validation-provider': Vue.extend(ValidationProvider),
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    chapter: {
      type: Object,
      default: () => ({}),
    },
    submitting: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      chapterTextCopy: '',
    }
  },
  watch: {
    chapter: {
      handler ({ text }: { text: string }) {
        this.chapterTextCopy = text
      },
      immediate: true,
    },
  },
})
