















































import Vue, { PropType } from 'vue'
import LckAsyncImage from '@/components/ui/AsyncImage/AsyncImage.vue'
import { LckAttachment } from '@/services/lck-api/definitions'
import LckCellFileDialog from '@/components/ui/ColumnType/File/Dialog.vue'
import { getAttachmentsToDisplay } from './helpers'

export default Vue.extend({
  name: 'LckFileCell',
  components: {
    LckAsyncImage,
    LckCellFileDialog,
  },
  props: {
    attachments: {
      type: Array as PropType<LckAttachment[]>,
      default: () => [],
    },
    workspaceId: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    attachmentsToDisplay () {
      return getAttachmentsToDisplay(this.attachments, this.workspaceId)
    },
  },
  data () {
    return {
      dialogFileVisible: false,
    }
  },
})
