























































































import Vue from 'vue'

import { ValidationProvider } from 'vee-validate'

import { LckTableView } from '@/services/lck-api/definitions'

import InputSwitch from 'primevue/inputswitch'
import Dropdown from 'primevue/dropdown'

import AutoComplete from '@/components/ui/AutoComplete/AutoComplete.vue'

export default Vue.extend({
  name: 'TableSetSettingsFields',
  components: {
    'lck-autocomplete': AutoComplete,
    'p-dropdown': Vue.extend(Dropdown),
    'p-input-switch': Vue.extend(InputSwitch),
    'validation-provider': Vue.extend(ValidationProvider),
  },
  props: {
    addAllowed: {
      type: Boolean,
    },
    exportAllowed: {
      type: Boolean,
    },
    id: {
      type: String,
    },
    pageDetailId: {
      type: String,
    },
    pagination: {
      type: Number,
    },
    autocompleteSuggestions: {
      type: Array,
      default: () => ([]),
    } as Vue.PropOptions<{ label: string; value: string }[]>,
    tableViewDefinition: {
      type: Object as Vue.PropType<LckTableView | null>,
    },
    relatedChapterPages: {
      type: Array,
      default: () => ([]),
    },
  },
  data (): {
    tableView: { text: string; value: string } | null;
    } {
    return {
      tableView: null,
    }
  },
  computed: {
    availablePaginationValues (): { value: number; label: string }[] {
      return [20, 50, 100, -1].map(value => ({
        value: value,
        label: (value !== -1 ? value : Infinity).toLocaleString(),
      }))
    },
  },
  watch: {
    tableViewDefinition: {
      handler (view: LckTableView | null) {
        if (view) {
          this.tableView = {
            value: view.id,
            text: view.text,
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    onChangeTableView () {
      this.$emit('update:id', this.tableView?.value)
      this.$emit('component-refresh-required', true)
    },
    onChangePagination (pagination: number) {
      this.$emit('update:pagination', pagination)
      this.$emit('component-refresh-required', true)
    },
  },
})
