






















import Vue from 'vue'

import { ValidationProvider } from 'vee-validate'

import Textarea from 'primevue/textarea'

export default {
  name: 'ParagraphSettingsFields',
  components: {
    'p-textarea': Vue.extend(Textarea),
    'validation-provider': Vue.extend(ValidationProvider),
  },
  props: {
    content: {
      type: String,
    },
  },
}
