




























import Vue from 'vue'
import Button from 'primevue/button'
import Dialog from 'primevue/dialog'

export default Vue.extend({
  name: 'LckPopupReload',
  components: {
    'p-button': Vue.extend(Button),
    'p-dialog': Vue.extend(Dialog),
  },
  methods: {
    reload () {
      // Reload of the home app
      window.location.reload()
    },
  },
})
