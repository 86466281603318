


































































































import Vue, { PropType } from 'vue'

import Button from 'primevue/button'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import InputSwitch from 'primevue/inputswitch'
import { ValidationProvider } from 'vee-validate'
import LckForm from '@/components/ui/Form/Form.vue'
import RadioButton from 'primevue/radiobutton'

const MODE_NAVIGATION = ['anchor', 'tab']

export default Vue.extend({
  name: 'UpdatePageForm',
  components: {
    'lck-form': LckForm,
    'p-button': Vue.extend(Button),
    'p-datatable': Vue.extend(DataTable),
    'p-column': Vue.extend(Column),
    'p-switch': Vue.extend(InputSwitch),
    'p-radio-button': Vue.extend(RadioButton),
    'validation-provider': Vue.extend(ValidationProvider),
  },
  props: {
    page: {
      type: Object,
      required: true,
    },
    containers: {
      type: Array,
      required: true,
    },
    submitting: {
      type: Boolean,
      default: false,
    },
    autocompleteSuggestions: {
      type: Array as PropType<{ label: string; value: string }[]>,
      default: () => [],
    },
  },
  data () {
    return {
      MODE_NAVIGATION,
      modeNavigation: this.page.modeNavigation, // Always set
    }
  },
  methods: {
    onFormSubmit () {
      return this.$emit('updateModeNavigation', this.modeNavigation)
    },
    radioChange (value: string, validate: Function) {
      if (value !== this.modeNavigation) {
        this.modeNavigation = value
        validate(value);
        // setFlags belongs to ValidationProvider which extend Element
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this.$refs['vp-mode-navigation'] as any).setFlags({
          pristine: false,
          dirty: true,
          touched: true,
          untouched: false,
        })
      }
    },
  },
})
