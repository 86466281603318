




















































import Vue, { PropType } from 'vue'

import Button from 'primevue/button'

enum ANCHOR_CLASS {
  DANGER = 'danger',
  WARNING = 'warning',
  SUCCESS = 'success',
  PRIMARY = 'primary',
  SECONDARY = 'secondary'
}

interface Containers {
  id: string;
  text?: string;
  position?: number;
  displayed_in_navbar: boolean;
  anchor_label?: string;
  anchor_icon?: string;
  anchor_icon_class?: ANCHOR_CLASS;
  settings?: {};
}

export default Vue.extend({
  name: 'NavAnchorLink',
  components: {
    'p-button': Vue.extend(Button),
  },
  props: {
    containers: {
      type: Array as PropType<Containers[]>,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      anchorContainerActive: this.$route?.hash,
    }
  },
  computed: {
    containersToDisplayed (): Containers[] {
      // eslint-disable-next-line @typescript-eslint/camelcase
      return this.containers.filter(container => container.displayed_in_navbar)
    },
  },
  watch: {
    containers: {
      handler: function () {
        this.anchorContainerActive = this.containers && `#${this.containers[0].id}`
      },
    },
  },
})
