


































































































import Vue, { PropOptions } from 'vue'
import { TranslateResult } from 'vue-i18n'
import draggable from 'vuedraggable'

import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import Button from 'primevue/button'

interface MenuItems {
  id: string|null;
  label: string|TranslateResult;
  subitems?: {
    hidden: null|boolean;
    id: string|null;
    label: string|TranslateResult;
    to: string;
  }[];
}

export default Vue.extend({
  name: 'Sidebar',
  components: {
    'p-accordion': Accordion,
    'p-accordion-tab': AccordionTab,
    'p-button': Button,
    draggable: draggable,
  },
  props: {
    items: {
      type: Array,
    } as PropOptions<MenuItems[]>,
    displayEditActions: {
      type: Boolean,
      default: false,
    },
    createItemLabel: {
      type: String,
    } as PropOptions<string|TranslateResult>,
    createSubItemLabel: {
      type: String,
    } as PropOptions<string|TranslateResult>,
    sidebarActive: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      activeAccordions: [0] as number[],
    }
  },
  computed: {
    isItemsEmpty (): boolean {
      return !this.items || this.items.length === 0
    },
  },
  methods: {
    findActiveAccordion () {
      if (this.$route.params.pageId && this.items) {
        this.activeAccordions = this.items.reduce((acc, item, index) => {
          if (item.subitems) {
            const currentPage = item.subitems.find(({ id }) => id === this.$route.params.pageId)
            if (currentPage) {
              acc.push(index)
            }
          }
          return acc
        }, [] as number[])
      }
    },
  },
  mounted () {
    this.findActiveAccordion()
  },
  watch: {
    items: function () {
      this.findActiveAccordion()
    },
  },
})
