































































































































































































































































































































































































































import Vue, { PropType } from 'vue'

import { ValidationProvider } from 'vee-validate'
import { LckTableView } from '@/services/lck-api/definitions'
import { COLUMN_TYPE, MapSourceSettings } from '@locokit/lck-glossary'

import Button from 'primevue/button'
import Dropdown from 'primevue/dropdown'
import InputSwitch from 'primevue/inputswitch'
import InputText from 'primevue/inputtext'
import InputNumber from 'primevue/inputnumber'

import AutoComplete from '@/components/ui/AutoComplete/AutoComplete.vue'
import {
  EXTENDED_NAMED_CLASSES,
} from '@/services/lck-utils/prime'
import {
  GEOGRAPHIC_COLUMN_TYPES,
} from '@/services/lck-utils/columns'
import { TranslateResult } from 'vue-i18n'

interface Item {
  text: string;
  value: string;
}

interface SourceOptions {
  aggregationField?: Item;
  field?: Item;
  popupFields?: Item[];
  popupTitle?: Item;
  tableView?: Item;
}

export default Vue.extend({
  name: 'MapSettingsFields',
  components: {
    'lck-autocomplete': AutoComplete,
    'p-button': Vue.extend(Button),
    'p-dropdown': Vue.extend(Dropdown),
    'p-input-switch': Vue.extend(InputSwitch),
    'p-input-text': Vue.extend(InputText),
    'p-input-number': Vue.extend(InputNumber),
    'validation-provider': Vue.extend(ValidationProvider),
  },
  props: {
    addAllowed: {
      type: Boolean,
    },
    addSourceId: {
      type: String,
    },
    addButtonTitle: {
      type: String,
    },
    sources: {
      type: Array as PropType<MapSourceSettings[]>,
      default: () => ([]),
    },
    autocompleteSuggestions: {
      type: Array as PropType<{ label: string; value: string }[]>,
      default: () => ([]),
    },
    tableViewDefinition: {
      type: Object as PropType<Record<string, LckTableView> | null>,
    },
    relatedChapterPages: {
      type: Array,
      default: () => ([]),
    },
    singleSource: {
      type: Boolean,
      default: false,
    },
  },
  data (): {
    EXTENDED_NAMED_CLASSES: { label: string; value: string }[];
    DISPLAYABLE_COLUMN_TYPES: COLUMN_TYPE[];
    COLUMN_TYPE: typeof COLUMN_TYPE;
    sourcesOptions: SourceOptions[];
    tableViewIdsNamesAssociation: Record<string, string>;
    fieldIdsNamesAssociationByTableView: Record<string, Record<string, string>>;
    tableViewUniqueOptions: Item[];
    } {
    return {
      EXTENDED_NAMED_CLASSES,
      sourcesOptions: [],
      tableViewIdsNamesAssociation: {},
      fieldIdsNamesAssociationByTableView: {},
      tableViewUniqueOptions: [],
      COLUMN_TYPE,
      DISPLAYABLE_COLUMN_TYPES: [
        ...GEOGRAPHIC_COLUMN_TYPES,
        COLUMN_TYPE.VIRTUAL_LOOKED_UP_COLUMN,
        COLUMN_TYPE.LOOKED_UP_COLUMN,
      ],
    }
  },
  watch: {
    sources: {
      handler (newSources: MapSourceSettings[]) {
        this.updateSourceViewOptions(newSources)
      },
      immediate: true,
    },
    tableViewDefinition: {
      handler (newTableViewDefinition: Record<string, LckTableView> | null) {
        this.updateTableViewIdsTextsAssociation(newTableViewDefinition)
        this.updateSourceViewOptions(this.sources)
      },
      immediate: true,
    },
  },
  computed: {
    needRelatedSourcePlaceholder (): TranslateResult[] {
      // For each source, return a specific placeholder for the autocomplete inputs that need the view to be chosen
      return this.sources.map(source => {
        return source.id
          ? this.$t('components.datatable.autoCompletePlaceholder')
          : this.$t('pages.workspace.block.map.noSelectedView')
      })
    },
    queryParametersOnAggregation (): ({
        settings: {
          localField: string;
        };
      } | {
        settings?: undefined;
      })[] {
      // If the source data is aggregated, only the columns related to the aggregation field should be used
      // (in the popup, to customise the layers...) because their values are similar in the aggregated rows.
      return this.sources.map(source => {
        return source.aggregationField
          ? {
            settings: {
              localField: source.aggregationField,
            },
          }
          : {}
      })
    },
  },
  methods: {
    // Map settings
    onAddAllowedChange (allowed: boolean) {
      if (!allowed) {
        // Reset related inputs
        this.$emit('update:addSourceId', undefined)
        this.$emit('update:addButtonTitle', undefined)
      }
      this.$emit('update:addAllowed', allowed)
    },
    // Map source settings
    onTableViewChange (index: number, { value }: { value: { value: string; text: string }}) {
      // Save the id and the name of the selected table view
      this.tableViewIdsNamesAssociation[value.value] = value.text
      if (!this.fieldIdsNamesAssociationByTableView[value.value]) {
        this.fieldIdsNamesAssociationByTableView[value.value] = {}
      }
      if (this.sources[index].id !== value.value) {
        // Update the settings and ask new data
        this.$emit('update-map-source-id', { index, id: value.value })
        this.$emit('component-refresh-required', true)
      }
    },
    onTableViewReset (index: number) {
      this.$emit('update-map-source-id', { index, id: null })
    },
    onFieldChange (source: MapSourceSettings, { value }: { value: { value: string; text: string }}) {
      // Save the id and the name of the selected field
      this.saveFieldItem(source, { value })
      // Update the settings and ask new data
      this.onSourcePropertyChange(source, 'field', value.value)
      this.$emit('component-refresh-required', true)
    },
    onAggregationFieldChange (source: MapSourceSettings, { value }: { value: { value: string; text: string }}) {
      // Save the id and the name of the selected aggregation field
      this.saveFieldItem(source, { value })
      // Update the settings and ask new data
      this.onSourcePropertyChange(source, 'aggregationField', value.value)
      this.$emit('component-refresh-required', true)
    },
    onSourcePropertyChange (
      source: MapSourceSettings,
      propertyName: string,
      propertyValue: object | string | boolean | null,
    ) {
      this.$emit('update-map-source-property', { source, propertyName, propertyValue })
    },
    // Map source pop-up settings
    onSourcePopupPropertyChange (
      source: MapSourceSettings,
      propertyName: string,
      propertyValue: object | string | boolean | null,
    ) {
      this.$emit('update-map-source-popup-property', { source, propertyName, propertyValue })
    },
    onPopupFieldChange (content: { field: string; class?: string }, propertyName: string, propertyValue: string | undefined) {
      this.$emit('update-map-source-popup-content-property', { content, propertyName, propertyValue })
    },
    onPopupFieldAdd (popupSettings: object, index: number) {
      if (!Array.isArray(this.sourcesOptions[index].popupFields)) {
        this.$set(this.sourcesOptions[index], 'popupFields', [])
      }
      this.$emit('add-map-source-popup-content', popupSettings)
    },
    onPopupFieldDelete (contentFields: { class?: string; field: string }[], index: number, popupIndex: number) {
      const popupFieldsOptions = this.sourcesOptions[index].popupFields
      if (Array.isArray(popupFieldsOptions)) {
        popupFieldsOptions.splice(popupIndex, 1)
      }
      this.$emit('delete-map-source-popup-content', { contentFields: contentFields, index: popupIndex })
    },
    // Utils to manage the autocomplete inputs
    saveFieldItem (source: MapSourceSettings, { value }: { value: { value: string; text: string }}) {
      // Save the id and the name of the current field
      this.fieldIdsNamesAssociationByTableView[source.id][value.value] = value.text
    },
    updateTableViewIdsTextsAssociation (definitions: Record<string, LckTableView> | null) {
      // Build an object making the association between the view id and the view name
      for (const viewId in definitions) {
        this.tableViewIdsNamesAssociation[viewId] = definitions[viewId].text
        if (!this.fieldIdsNamesAssociationByTableView[viewId]) this.fieldIdsNamesAssociationByTableView[viewId] = {}
        for (const column of definitions[viewId].columns || []) {
          this.fieldIdsNamesAssociationByTableView[viewId][column.id] = column.text
        }
      }
    },
    updateSourceViewOptions (sources: MapSourceSettings[]) {
      // Build an array containing the autocomplete options of each source
      const options: SourceOptions[] = []
      const tableViewOptions: Item[] = []
      const alreadySourceViewIds: Set<string> = new Set()
      let addSourceIdExist = false

      sources.forEach(({ id: tableViewId, field, aggregationField, popupSettings }, index) => {
        const currentSourceOptions: SourceOptions = {}
        // Input related to the source view
        if (tableViewId) {
          currentSourceOptions.tableView = {
            value: tableViewId,
            text: this.tableViewIdsNamesAssociation[tableViewId],
          }
          if (this.addSourceId === tableViewId) addSourceIdExist = true

          if (!alreadySourceViewIds.has(tableViewId)) {
            tableViewOptions.push({
              value: tableViewId,
              text: this.tableViewIdsNamesAssociation[tableViewId] || `${this.$t('components.mapview.noReference')} (${index + 1})`,
            })
            alreadySourceViewIds.add(tableViewId)
          }
        }
        // Input related to the source aggregation field
        if (aggregationField) {
          currentSourceOptions.aggregationField = {
            value: aggregationField,
            text: this.fieldIdsNamesAssociationByTableView[tableViewId]?.[aggregationField],
          }
        }
        // Input related to the source field
        if (field) {
          currentSourceOptions.field = {
            value: field,
            text: this.fieldIdsNamesAssociationByTableView[tableViewId]?.[field],
          }
        }
        // Inputs related to the source popup
        if (popupSettings) {
          if (popupSettings.title) {
            currentSourceOptions.popupTitle = {
              value: popupSettings.title,
              text: this.fieldIdsNamesAssociationByTableView[tableViewId]?.[popupSettings.title],
            }
          }
          if (popupSettings.contentFields) {
            currentSourceOptions.popupFields = popupSettings.contentFields.map(({ field: contentField }) => ({
              value: contentField,
              text: this.fieldIdsNamesAssociationByTableView[tableViewId]?.[contentField],
            }))
          }
        }
        options.push(currentSourceOptions)
      })
      this.sourcesOptions = options
      this.tableViewUniqueOptions = tableViewOptions
      if (!addSourceIdExist) this.$emit('update:addSourceId', undefined)
    },
  },
})
