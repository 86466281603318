





















































































































































































import Vue from 'vue'
import { ValidationProvider } from 'vee-validate'

import {
  LckAclSet,
  LckAclTable,
  LckChapter,
  LckWorkspace,
} from '@/services/lck-api/definitions'

import Button from 'primevue/button'
import Column from 'primevue/column'
import ColumnGroup from 'primevue/columngroup'
import DataTable from 'primevue/datatable'
import InputSwitch from 'primevue/inputswitch'
import InputText from 'primevue/inputtext'
import Row from 'primevue/row'
import Card from 'primevue/card'

import AutoComplete from '@/components/ui/AutoComplete/AutoComplete.vue'
import Form from '@/components/ui/Form/Form.vue'
import JSONField from '@/components/ui/JSONField/JSONField.vue'
import OverlayPanel from '@/components/ui/OverlayPanel/OverlayPanel.vue'
import StateButton from '@/components/ui/StateButton/StateButton.vue'

type PropertyStyle = {
  icon: string;
  label: string;
  style?: string;
}

export default Vue.extend({
  name: 'AclSetForm',
  components: {
    'p-button': Vue.extend(Button),
    'p-card': Vue.extend(Card),
    'p-column': Vue.extend(Column),
    'p-column-group': Vue.extend(ColumnGroup),
    'p-datatable': Vue.extend(DataTable),
    'p-input-switch': Vue.extend(InputSwitch),
    'p-input-text': Vue.extend(InputText),
    'p-row': Vue.extend(Row),
    'validation-provider': Vue.extend(ValidationProvider),
    'lck-autocomplete': AutoComplete,
    'lck-form': Form,
    'lck-json-field': JSONField,
    'lck-overlaypanel': OverlayPanel,
    'lck-state-button': StateButton,
  },
  props: {
    aclSet: {
      type: Object,
      required: true,
    } as Vue.PropOptions<LckAclSet>,
    chapterSuggestions: {
      type: Array,
      default: () => [],
    } as Vue.PropOptions<LckChapter[]>,
    submitting: {
      type: Object,
      default: () => ({
        aclSet: false,
      }),
    } as Vue.PropOptions<{
      aclSet: false;
    }>,
    workspace: {
      type: Object,
      default: null,
    } as Vue.PropOptions<LckWorkspace>,
    focusedCell: {
      type: String,
      default: '',
    },
  },
  data (): {
    aclStateButtonStyle: Record<
      'true' | 'false',
      {
        class: string;
        style: string;
        icon: string;
      }
    >;
    aclTableProperties: PropertyStyle[];
    aclTableFilters: PropertyStyle[];
    aclTables: Record<string, LckAclTable>;
    } {
    return {
      aclTables: {},
      aclStateButtonStyle: {
        true: {
          class: 'p-button-text',
          style: 'color: green;',
          icon: 'pi pi-check',
        },
        false: {
          class: 'p-button-text',
          style: 'color: red;',
          icon: 'pi pi-ban',
        },
      },
      aclTableFilters: [
        {
          icon: 'pi pi-eye',
          label: 'read_filter',
        },
        {
          icon: 'pi pi-pencil',
          label: 'update_filter',
        },
        {
          icon: 'pi pi-trash',
          label: 'delete_filter',
        },
      ],
      aclTableProperties: [
        {
          icon: 'pi pi-plus',
          label: 'create_rows',
        },
        {
          icon: 'pi pi-eye',
          label: 'read_rows',
        },
        {
          icon: 'pi pi-pencil',
          label: 'update_rows',
        },
        {
          icon: 'pi pi-trash',
          label: 'delete_rows',
          style: 'border-right-width: 1px;',
        },
      ],
    }
  },
  computed: {
    allAclTableProperties (): PropertyStyle[] {
      return this.aclTableProperties.concat(this.aclTableFilters)
    },
  },
  methods: {
    setAclTable (aclTable: LckAclTable, index: number, data: Partial<LckAclTable>) {
      this.$emit('set-acltable', {
        index,
        aclTable,
        newData: data,
      })
    },
    onError () {
      this.$toast.add({
        severity: 'error',
        summary: this.$t('error.basic'),
        detail: this.$t('pages.workspaceAdmin.acl.detail.filterError'),
        life: 5000,
      })
    },
  },
})
