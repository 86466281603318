





































































































































































































































































































































import Vue from 'vue'

import Card from 'primevue/card'
import InputText from 'primevue/inputtext'
import Tag from 'primevue/tag'
import Password from 'primevue/password'
import { ValidationProvider } from 'vee-validate'

import LckForm from '@/components/ui/Form/Form.vue'

import { lckClient } from '@/services/lck-api'
import { authState, logout, updateUsername } from '@/store/auth'
import { ROUTES_PATH } from '@/router/paths'
import { regexPasswordRules } from '@/services/lck-utils/regex'

export default Vue.extend({
  name: 'Profile',
  components: {
    'lck-form': LckForm,
    'p-card': Vue.extend(Card),
    'p-password': Vue.extend(Password),
    'p-input-text': Vue.extend(InputText),
    'p-tag': Vue.extend(Tag),
    'validation-provider': Vue.extend(ValidationProvider),
  },
  data () {
    return {
      authState,
      loading: false,
      username: authState.data.user?.name,
      password: {
        oldPassword: null,
        password: null,
        passwordCheck: null,
      },
      emailEdit: {
        newEmail: null,
        password: null,
      } as { newEmail: null | string; password: null | string },
      displayErrorMismatch: false,
      incorrectPassword: false,
      errorPasswordRules: null,
      regexPasswordRules,
      ROUTES_PATH,
    }
  },
  methods: {
    async submitPassword () {
      this.loading = true
      this.errorPasswordRules = null
      this.incorrectPassword = false
      try {
        await lckClient.service('authManagement').create({
          action: 'passwordChange',
          value: {
            user: { email: authState.data.user?.email },
            ...this.password,
          },
        })
        this.password = {
          oldPassword: null,
          password: null,
          passwordCheck: null,
        }
      } catch (error) {
        this.$toast.add({
          severity: 'error',
          summary: this.$t('error.impossibleOperation'),
          detail: this.$t('error.basic'),
          life: 5000,
        })
      }
      this.loading = false
    },
    async submitUsername () {
      if (this.username) {
        try {
          await lckClient.service('user').patch(this.authState.data.user?.id, {
            name: this.username,
          })
          updateUsername(this.username)
        } catch (error) {
          this.$toast.add({
            severity: 'error',
            summary: this.$t('error.impossibleOperation'),
            detail: this.$t('error.basic'),
            life: 5000,
          })
        }
      }
    },
    async submitEmail () {
      // The user and the new email address must be defined
      if (!authState.data.user || !this.emailEdit.newEmail) return
      try {
        // API call
        this.loading = true
        await lckClient.service('authManagement').create({
          action: 'identityChange',
          value: {
            user: { email: authState.data.user.email },
            password: this.emailEdit.password,
            changes: { email: this.emailEdit.newEmail },
          },
        })
        // Display pop-up
        this.$toast.add({
          severity: 'success',
          summary: this.$t('success.save'),
          detail: this.$t('pages.account.edit.email.success'),
          life: 5000,
        })
      } catch (error) {
        this.$toast.add({
          severity: 'error',
          summary: this.$t('error.impossibleOperation'),
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          detail: (error as any).errors?.password
            ? this.$t('pages.account.edit.passwordIncorrect')
            : this.$t('error.basic'),
          life: 5000,
        })
      }
      this.loading = false
    },
    logout () {
      logout()
      this.$router.push(ROUTES_PATH.HOME)
    },
  },
})
