






























import Vue from 'vue'

import LayoutWithBackground from '@/layouts/WithBackground.vue'
import LostPassword from '@/components/auth/LostPassword/LostPassword.vue'
import { lckClient } from '@/services/lck-api'
import { ROUTES_NAMES } from '@/router/paths'

export default Vue.extend({
  name: 'LostPassword',
  data () {
    return {
      loading: false,
      sendResetOK: false,
      error: null as Error | null,
      ROUTES_NAMES,
    }
  },
  components: {
    'layout-with-background': Vue.extend(LayoutWithBackground),
    'lost-password': Vue.extend(LostPassword),
  },
  methods: {
    async sendResetPasswordLink (email: string) {
      this.loading = true
      try {
        await lckClient.service('authManagement').create({
          action: 'sendResetPwd',
          value: {
            email,
          },
        })
        this.error = null
        this.sendResetOK = true
      } catch (err) {
        this.error = err as Error
      }
      this.loading = false
    },
  },
})

