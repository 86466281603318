






























































import Vue from 'vue'

import { ROUTES_NAMES } from '@/router/paths'

import Password from 'primevue/password'
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'

export default Vue.extend({
  name: 'LckLogin',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Error,
      default: null,
    },
    logInAgain: {
      type: Boolean,
      default: false,
    },
    displaySignUpLink: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      ROUTES_NAMES,
      form: {
        email: '',
        password: '',
      },
    }
  },
  components: {
    'p-input-text': Vue.extend(InputText),
    'p-button': Vue.extend(Button),
    'p-password': Vue.extend(Password),
  },
  methods: {
    emitSubmit () {
      this.$emit('submit', this.form)
    },
  },
})
