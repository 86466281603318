var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"lck-color-primary"},[_vm._v(" "+_vm._s(_vm.$t('components.datatable.column.edit'))+" ")]),_c('lck-form',{attrs:{"displayCancelButton":false,"submitting":_vm.submitting},on:{"submit":_vm.submitColumnData}},[_c('validation-provider',{staticClass:"p-field",attrs:{"vid":"columnTextField","tag":"div","name":_vm.$t('components.datatable.column.title'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
      var classes = ref.classes;
return [_c('label',{staticClass:"label-field-required",attrs:{"for":"columnTextField"}},[_vm._v(_vm._s(_vm.$t('components.datatable.column.title')))]),_c('p-input-text',{attrs:{"id":"columnTextField"},model:{value:(_vm.columnCopy.text),callback:function ($$v) {_vm.$set(_vm.columnCopy, "text", $$v)},expression:"columnCopy.text"}}),_c('span',{class:classes},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('validation-provider',{staticClass:"p-field",attrs:{"vid":"columnDocField","tag":"div","name":_vm.$t('components.datatable.column.doc')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
      var classes = ref.classes;
return [_c('label',{attrs:{"for":"columnDocField"}},[_vm._v(_vm._s(_vm.$t('components.datatable.column.doc')))]),_c('p-textarea',{attrs:{"id":"columnDocField"},model:{value:(_vm.columnCopy.documentation),callback:function ($$v) {_vm.$set(_vm.columnCopy, "documentation", $$v)},expression:"columnCopy.documentation"}}),_c('span',{class:classes},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('label',{attrs:{"for":"columnUUID"}},[_vm._v(" "+_vm._s(_vm.$t('components.datatable.column.id'))+" ")]),_c('p',[_vm._v(_vm._s(_vm.columnCopy.id))]),_c('div',[_vm._v(" "+_vm._s(_vm.$t('pages.databaseSchema.handleColumnModal.validation'))+" "),_c('lck-column-validation',{staticClass:"p-my-2",attrs:{"columnType":_vm.columnCopy.column_type_id,"columnValidation":_vm.columnCopy.validation}})],1),(_vm.isSelectColumnType)?_c('div',{staticClass:"p-mb-3"},[_c('p',[_vm._v(_vm._s(_vm.$t('components.datatable.column.values')))]),_c('lck-select-type-column',{attrs:{"columnToHandle":_vm.columnCopy},on:{"select-type-values-change":_vm.selectTypeValuesChange,"default-select-type-value-id-change":_vm.defaultSelectTypeValueIdChange}})],1):_vm._e()],1),_c('div',{staticClass:"column-form-container lck-color-content"},[_c('h2',{staticClass:"lck-color-title"},[_vm._v(_vm._s(_vm.$t('components.datatable.column.visualizationConfiguration')))]),_c('lck-form',{attrs:{"displayCancelButton":false,"submitting":_vm.submitting},on:{"submit":_vm.submitTableViewColumnData}},[_c('validation-provider',{staticClass:"p-field p-d-flex",attrs:{"vid":"columnTransmittedField","tag":"div","name":_vm.$t('components.datatable.column.transmitted'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
        var classes = ref.classes;
return [_c('p-checkbox',{staticClass:"p-mr-2",attrs:{"binary":true,"id":"columnTransmittedField"},model:{value:(_vm.columnCopy.transmitted),callback:function ($$v) {_vm.$set(_vm.columnCopy, "transmitted", $$v)},expression:"columnCopy.transmitted"}}),_c('div',[_c('label',{attrs:{"for":"columnTransmittedField"}},[_vm._v(_vm._s(_vm.$t('components.datatable.column.transmitted')))]),_c('p',{staticClass:"p-my-0",class:classes},[_vm._v(_vm._s(errors[0]))])])]}}])}),_c('validation-provider',{staticClass:"p-field p-d-flex",attrs:{"vid":"columnDisplayedField","tag":"div","name":_vm.$t('components.datatable.column.displayed'),"immediate":"","rules":"isFalseOrOtherTrue:@columnTransmittedField"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
        var classes = ref.classes;
return [_c('p-checkbox',{staticClass:"p-mr-2",attrs:{"binary":true,"id":"columnDisplayedField"},model:{value:(_vm.columnCopy.displayed),callback:function ($$v) {_vm.$set(_vm.columnCopy, "displayed", $$v)},expression:"columnCopy.displayed"}}),_c('div',[_c('label',{attrs:{"for":"columnDisplayedField"}},[_vm._v(_vm._s(_vm.$t('components.datatable.column.displayed')))]),_c('p',{staticClass:"p-my-0",class:classes},[_vm._v(_vm._s(errors[0]))])])]}}])}),_c('validation-provider',{staticClass:"p-field p-d-flex",attrs:{"vid":"columnEditableField","tag":"div","name":_vm.$t('components.datatable.column.editable'),"rules":"isFalseOrOtherTrue:@columnTransmittedField","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
        var classes = ref.classes;
return [_c('p-checkbox',{staticClass:"p-mr-2",attrs:{"binary":true,"id":"columnEditableField"},model:{value:(_vm.columnCopy.editable),callback:function ($$v) {_vm.$set(_vm.columnCopy, "editable", $$v)},expression:"columnCopy.editable"}}),_c('div',[_c('label',{attrs:{"for":"columnEditableField"}},[_vm._v(_vm._s(_vm.$t('components.datatable.column.editable')))]),_c('p',{staticClass:"p-my-0",class:classes},[_vm._v(_vm._s(errors[0]))])])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }