






























import Vue from 'vue'
import LayoutWithBackground from '@/layouts/WithBackground.vue'
import ResetPassword from '@/components/auth/ResetPassword/ResetPassword.vue'
import { lckClient } from '@/services/lck-api'

export default Vue.extend({
  name: 'VerifySignup',
  data () {
    return {
      loading: false,
      verifyOK: false,
      error: null,
    }
  },
  components: {
    'layout-with-background': Vue.extend(LayoutWithBackground),
    'reset-password': Vue.extend(ResetPassword),
  },
  methods: {
    async verifySignupAndSetPassword (password: string) {
      this.loading = true
      try {
        await lckClient.service('authManagement').create({
          action: 'verifySignupSetPasswordLong',
          value: {
            token: this.$route.query?.token,
            password,
          },
        })
        this.error = null
        this.verifyOK = true
      } catch (err) {
        this.error = err as any
      }
      this.loading = false
    },
  },
})

