var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('lck-form',{attrs:{"submitting":_vm.submitting},on:{"cancel":function($event){return _vm.$emit('cancel')},"submit":function($event){return _vm.$emit('input', _vm.newWorkspace)}}},[_c('validation-provider',{staticClass:"p-field",attrs:{"vid":"text","tag":"div","name":_vm.$t('components.workspaceForm.fields.textLabel'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
    var classes = ref.classes;
return [_c('label',{staticClass:"label-field-required",attrs:{"for":"text"}},[_vm._v(" "+_vm._s(_vm.$t('components.workspaceForm.fields.textLabel'))+" ")]),_c('p-input-text',{attrs:{"id":"text","placeholder":_vm.$t('components.workspaceForm.fields.textPlaceholder'),"autofocus":""},model:{value:(_vm.newWorkspace.text),callback:function ($$v) {_vm.$set(_vm.newWorkspace, "text", $$v)},expression:"newWorkspace.text"}}),(errors.length > 0)?_c('p',{class:classes},[_vm._v(_vm._s(errors.join(' ')))]):_vm._e()]}}])}),_c('validation-provider',{staticClass:"p-field",attrs:{"vid":"generate_sql","tag":"div","name":_vm.$t('components.workspaceForm.fields.generateLabel')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
    var classes = ref.classes;
return [_c('p-checkbox',{attrs:{"binary":true,"id":"generate_sql"},model:{value:(_vm.newWorkspace.generate_sql),callback:function ($$v) {_vm.$set(_vm.newWorkspace, "generate_sql", $$v)},expression:"newWorkspace.generate_sql"}}),_c('label',{staticClass:"p-mb-0 p-ml-2",attrs:{"for":"generate_sql"}},[_vm._v(" "+_vm._s(_vm.$t('components.workspaceForm.fields.generateLabel'))+" ")]),_c('p',{staticClass:"p-my-0",class:classes},[_vm._v(_vm._s(errors.join(' ')))])]}}])}),(_vm.newWorkspace.generate_sql === true)?_c('validation-provider',{staticClass:"p-field",attrs:{"vid":"slug","tag":"div","name":_vm.$t('components.workspaceForm.fields.slugLabel'),"rules":{
      required_if: {
        generate_sql: true
      },
      snakeCase: true
    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
    var classes = ref.classes;
return [_c('label',{staticClass:"label-field-required",attrs:{"for":"slug"}},[_vm._v(" "+_vm._s(_vm.$t('components.workspaceForm.fields.slugLabel'))+" ")]),_c('p-input-text',{attrs:{"id":"slug","placeholder":_vm.$t('components.workspaceForm.fields.slugPlaceholder'),"autofocus":""},model:{value:(_vm.newWorkspace.slug),callback:function ($$v) {_vm.$set(_vm.newWorkspace, "slug", $$v)},expression:"newWorkspace.slug"}}),(errors.length > 0)?_c('p',{class:classes},[_vm._v(_vm._s(errors.join(' ')))]):_vm._e(),_c('small',{attrs:{"id":"slug"}},[_vm._v(_vm._s(_vm.$t('components.workspaceForm.fields.slugHelp')))])]}}],null,false,3466799861)}):_vm._e(),_c('validation-provider',{staticClass:"p-field",attrs:{"vid":"text","tag":"div","name":_vm.$t('components.workspaceForm.fields.textLabel')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
    var classes = ref.classes;
return [_c('label',{attrs:{"for":"documentation"}},[_vm._v(" "+_vm._s(_vm.$t('components.workspaceForm.fields.docLabel'))+" ")]),_c('p-textarea',{staticClass:"p-mb-2",attrs:{"id":"documentation","placeholder":_vm.$t('components.workspaceForm.fields.docPlaceholder'),"autoResize":true},model:{value:(_vm.newWorkspace.documentation),callback:function ($$v) {_vm.$set(_vm.newWorkspace, "documentation", $$v)},expression:"newWorkspace.documentation"}}),(errors.length > 0)?_c('p',{class:classes},[_vm._v(_vm._s(errors.join(' ')))]):_vm._e()]}}])}),_c('validation-provider',{staticClass:"p-field",attrs:{"vid":"color","tag":"div","name":_vm.$t('components.workspaceForm.fields.colorLabel')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
    var classes = ref.classes;
return [_c('label',{attrs:{"for":"color"}},[_vm._v(" "+_vm._s(_vm.$t('components.workspaceForm.fields.colorLabel'))+" ")]),_c('p-dropdown',{attrs:{"id":"color","options":_vm.colorScheme,"dataKey":"backgroundColor","appendTo":"body","showClear":true,"placeholder":_vm.$t('components.workspaceForm.fields.colorPlaceholder'),"value":_vm.newWorkspaceColorScheme},on:{"change":function($event){return _vm.onColorSelect($event)}},scopedSlots:_vm._u([{key:"value",fn:function(slotProps){return [(slotProps.value)?_c('lck-badge',{attrs:{"label":_vm.$t('components.workspaceForm.fields.colorLabel') + ' ' + slotProps.value.backgroundColor,"color":slotProps.value.color,"backgroundColor":slotProps.value.backgroundColor}}):_vm._e()]}},{key:"option",fn:function(slotProps){return [_c('lck-badge',{attrs:{"label":_vm.$t('components.workspaceForm.fields.colorLabel') + ' ' + slotProps.option.backgroundColor,"color":slotProps.option.color,"backgroundColor":slotProps.option.backgroundColor}})]}}],null,true)}),_c('small',{attrs:{"id":"color"}},[_vm._v(_vm._s(_vm.$t('components.workspaceForm.fields.colorHelp')))]),(errors.length > 0)?_c('p',{class:classes},[_vm._v(_vm._s(errors.join(' ')))]):_vm._e()]}}])}),_c('validation-provider',{staticClass:"p-field",attrs:{"vid":"icon","tag":"div","name":_vm.$t('components.workspaceForm.fields.iconLabel')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
    var classes = ref.classes;
return [_c('label',{attrs:{"for":"icon"}},[_vm._v(" "+_vm._s(_vm.$t('components.workspaceForm.fields.iconLabel'))+" ")]),_c('p-input-text',{attrs:{"id":"icon","placeholder":_vm.$t('components.workspaceForm.fields.iconPlaceholder')},model:{value:(_vm.newWorkspace.settings.icon),callback:function ($$v) {_vm.$set(_vm.newWorkspace.settings, "icon", $$v)},expression:"newWorkspace.settings.icon"}}),_c('small',{attrs:{"id":"icon"}},[_vm._v(_vm._s(_vm.$t('components.workspaceForm.fields.iconHelp'))+" "),_c('a',{ref:"noopener",attrs:{"href":"https://icons.getbootstrap.com/","target":"_blank"}},[_vm._v("Bootstrap Icon")]),_vm._v(".")]),(errors.length > 0)?_c('p',{class:classes},[_vm._v(_vm._s(errors.join(' ')))]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }