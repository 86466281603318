


































import Vue from 'vue'
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'

export default Vue.extend({
  name: 'LckLostPassword',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Error,
      default: null,
    },
  },
  data () {
    return {
      email: '',
    }
  },
  components: {
    'p-input-text': Vue.extend(InputText),
    'p-button': Vue.extend(Button),
  },
  methods: {
    emitSubmit () {
      this.$emit('submit', this.email)
    },
  },
})
