



















































































































import Vue from 'vue'

import { ValidationProvider } from 'vee-validate'

import { MEDIA_TYPE } from '@locokit/lck-glossary'
import Dropdown from 'primevue/dropdown'
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'
import { TranslateResult } from 'vue-i18n'

export default Vue.extend({
  name: 'MediaSettingsFields',
  components: {
    'p-dropdown': Vue.extend(Dropdown),
    'p-input-text': Vue.extend(InputText),
    'p-button': Vue.extend(Button),
    'validation-provider': Vue.extend(ValidationProvider),
  },
  props: {
    displayMode: {
      type: String as Vue.PropType<MEDIA_TYPE>,
    },
    medias: {
      type: Array as Vue.PropType<{
        name: string;
        srcURL: string;
        type: MEDIA_TYPE.IMAGE | MEDIA_TYPE.VIDEO;
      }[]>,
      default: () => ([]),
    },
  },
  computed: {
    mediaTypes (): { label: TranslateResult; value: string }[] {
      return Object.entries(MEDIA_TYPE).map(mediaType => ({
        label: this.$t(`media.${mediaType[0]}`),
        value: mediaType[1],
      }))
    },
    basicMediaValues (): string [] {
      return [MEDIA_TYPE.IMAGE, MEDIA_TYPE.VIDEO]
    },
    basicMediaTypes (): { label: TranslateResult; value: string }[] {
      return this.mediaTypes.filter(
        mediaType => this.basicMediaValues.includes(mediaType.value),
      )
    },
    isBasicMedia (): boolean {
      return this.basicMediaValues.includes(this.displayMode)
    },
  },
})
