



























































import Vue from 'vue'

import { ValidationProvider } from 'vee-validate'

import InputText from 'primevue/inputtext'
import InputSwitch from 'primevue/inputswitch'
import RadioButton from 'primevue/radiobutton'

import LckDialogForm from '@/components/ui/DialogForm/DialogForm.vue'

const layoutTypes = [
  'classic',
  'center',
  'flex',
  'full',
]

export default Vue.extend({
  name: 'PageDialog',
  components: {
    'lck-dialog-form': LckDialogForm,
    'p-input-text': Vue.extend(InputText),
    'p-input-switch': Vue.extend(InputSwitch),
    'p-radio-button': Vue.extend(RadioButton),
    'validation-provider': Vue.extend(ValidationProvider),
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    page: {
      type: Object,
      default: () => ({}),
    },
    submitting: {
      type: Boolean,
      default: false,
    },
  },
  data (): {
    pageTextCopy: string;
    pageHiddenCopy: boolean;
    layoutTypes: typeof layoutTypes;
    selectedLayout: string | null;
    } {
    return {
      pageTextCopy: '',
      pageHiddenCopy: false,
      layoutTypes,
      selectedLayout: null,
    }
  },
  watch: {
    page: {
      handler ({ text, hidden, layout }: { text: string; hidden: boolean; layout: string }) {
        this.pageTextCopy = text
        this.pageHiddenCopy = hidden
        this.selectedLayout = layout || this.layoutTypes[0]
      },
      immediate: true,
    },
  },
})
