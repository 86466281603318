














































































































































































































































































































import Vue, { PropType, PropOptions } from 'vue'

import { ValidationProvider } from 'vee-validate'
import {
  ACTION_BUTTON_TYPE,
} from '@locokit/lck-glossary'

import {
  ACTIONS_TYPE,
  NAMED_CLASSES,
} from '@/services/lck-utils/prime'
import { LckTableView } from '@/services/lck-api/definitions'
import { ROUTES_NAMES } from '@/router/paths'

import InputText from 'primevue/inputtext'
import RadioButton from 'primevue/radiobutton'
import Dropdown from 'primevue/dropdown'
import Panel from 'primevue/panel'

import AutoComplete from '@/components/ui/AutoComplete/AutoComplete.vue'

export default Vue.extend({
  name: 'ActionButtonSettingsFields',
  components: {
    'lck-autocomplete': AutoComplete,
    'p-input-text': Vue.extend(InputText),
    'p-radio-button': Vue.extend(RadioButton),
    'p-panel': Vue.extend(Panel),
    'p-dropdown': Vue.extend(Dropdown),
    'validation-provider': Vue.extend(ValidationProvider),
  },
  props: {
    label: {
      type: String,
    },
    classButton: {
      type: String,
    },
    icon: {
      type: String,
    },
    action: {
      type: String,
    },
    processId: {
      type: String,
    },
    typePageTo: {
      type: String,
    },
    pageRedirectId: {
      type: String,
    },
    pageDetailId: {
      type: String,
    },
    pageQueryFieldId: {
      type: String,
    },
    displayFieldId: {
      type: String,
    },
    displayFieldConditionQuery: {
      type: String,
    },
    notificationSuccessTitle: {
      type: String,
    },
    notificationSuccessDescription: {
      type: String,
    },
    notificationErrorTitle: {
      type: String,
    },
    notificationErrorDescription: {
      type: String,
    },
    tableViewDefinition: {
      type: Object as PropType<LckTableView | null>,
    },
    autocompleteSuggestions: {
      type: Array,
      default: () => ([]),
    } as PropOptions<{ label: string; value: string }[]>,
  },
  data (): {
    ROUTES_NAMES: typeof ROUTES_NAMES;
    NAMED_CLASSES: typeof NAMED_CLASSES;
    ACTIONS_TYPE: typeof ACTIONS_TYPE;
    ACTION_BUTTON_TYPE: typeof ACTION_BUTTON_TYPE;
    tableView: { text: string; value: string } | null;
    } {
    return {
      ROUTES_NAMES,
      NAMED_CLASSES,
      ACTIONS_TYPE,
      ACTION_BUTTON_TYPE,
      tableView: null,
    }
  },
  methods: {
    onChangeTableView () {
      this.$emit('update:id', this.tableView?.value)
      this.$emit('component-refresh-required', true)
    },
  },
  watch: {
    tableViewDefinition: {
      handler (view: LckTableView | null) {
        if (view) {
          this.tableView = {
            value: view.id,
            text: view.text,
          }
        }
      },
      immediate: true,
    },
  },
})
