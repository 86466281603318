import { render, staticRenderFns } from "./DatabaseSchema.vue?vue&type=template&id=83ed2694&scoped=true&"
import script from "./DatabaseSchema.vue?vue&type=script&lang=ts&"
export * from "./DatabaseSchema.vue?vue&type=script&lang=ts&"
import style0 from "./DatabaseSchema.vue?vue&type=style&index=0&id=83ed2694&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "83ed2694",
  null
  
)

export default component.exports