
























































import Vue, { PropType } from 'vue'
import { BLOCK_TYPE, Block, BlockTableSet } from '@locokit/lck-glossary'
import Button from 'primevue/button'

import TableSet from '@/components/visualize/TableSet/TableSet.vue'
import Paragraph from '@/components/visualize/Paragraph/Paragraph.vue'
import Markdown from '@/components/visualize/Markdown/Markdown.vue'
import MapSet from '@/components/visualize/MapSet/MapSet.vue'
import HighlightField from '@/components/visualize/HighlightField/HighlightField.vue'
import Media from '@/components/visualize/Media/Media.vue'
import FormRecord from '@/components/visualize/FormRecord/FormRecord.vue'
import DataRecord from '@/components/visualize/DataRecord/DataRecord.vue'
import ActionButton from '@/components/visualize/ActionButton/ActionButton.vue'
import MarkdownField from '@/components/visualize/MarkdownField/MarkdownField.vue'
import ExternalApp from '@/components/visualize/ExternalApp/ExternalApp.vue'
import Error from '@/components/ui/Error/Error.vue'

export default Vue.extend({
  name: 'Block',
  components: {
    'p-button': Vue.extend(Button),
    TableSet,
    DataRecord,
    Paragraph,
    Markdown,
    Media,
    MapSet,
    MapField: MapSet,
    HighlightField,
    ActionButton,
    FormRecord,
    MarkdownField,
    Error,
    ExternalApp,
  },
  props: {
    block: {
      type: Object as PropType<Block>,
      required: true,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    workspaceId: {
      type: String,
      required: true,
    },
  },
  computed: {
    isBlockTypeValid () {
      const values = Object.values(BLOCK_TYPE) as string[]
      return values.includes(this.block.type)
    },
    isNotYetImplemented () {
      return [BLOCK_TYPE.KANBAN_SET, BLOCK_TYPE.CARD_SET].includes(this.block.type)
    },
    displayDetailButton () {
      if (this.block.type !== BLOCK_TYPE.TABLE_SET) return false
      return !!(this.block as BlockTableSet).settings?.pageDetailId
    },
    addAllowed () {
      if (this.block.type !== BLOCK_TYPE.TABLE_SET) return false
      return (this.block as BlockTableSet).settings?.addAllowed
    },
    exportAllowed () {
      if (this.block.type !== BLOCK_TYPE.TABLE_SET) return false
      return (this.block as BlockTableSet).settings?.exportAllowed
    },
    deleteAllowed () {
      if (this.block.type !== BLOCK_TYPE.TABLE_SET) return false
      return (this.block as BlockTableSet).settings?.deleteAllowed
    },
    duplicateAllowed () {
      if (this.block.type !== BLOCK_TYPE.TABLE_SET) return false
      return (this.block as BlockTableSet).settings?.duplicateAllowed
    },
    getBlockTypeClassname () {
      let className = (this.block?.elevation as boolean) ? 'lck-elevation ' : ''
      switch (this.block?.type) {
        case BLOCK_TYPE.TABLE_SET:
          className += 'lck-table-set'
          break
        case BLOCK_TYPE.KANBAN_SET:
          className += 'lck-kanban-set'
          break
        case BLOCK_TYPE.DATA_RECORD:
          className += 'lck-data-record'
          break
        case BLOCK_TYPE.PARAGRAPH:
          className += 'lck-paragraph'
          break
        case BLOCK_TYPE.MARKDOWN:
          className += 'lck-markdown'
          break
        case BLOCK_TYPE.MEDIA:
          className += 'lck-media'
          break
        case BLOCK_TYPE.ACTION_BUTTON:
          className += 'lck-action-button'
          break
        // case BLOCK_TYPE.MAP_SET:
        // case BLOCK_TYPE.MAP_FIELD:
          // className += 'lck-map-set'
        case BLOCK_TYPE.MARKDOWN_FIELD:
          className += 'lck-markdown-field'
          break
        default:
          className += 'lck-block-default'
      }
      return className
    },
  },
})
