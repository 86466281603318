























import Vue from 'vue'
import InputText from 'primevue/inputtext'

export default Vue.extend({
  name: 'LckURLInput',
  components: {
    'p-input-text': Vue.extend(InputText),
  },
  props: {
    appendTo: {
      type: String,
      default: 'body',
    },
    value: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      validURL: false,
    }
  },
  mounted () {
    this.validURL = this.urlValidity()
  },
  methods: {
    openLink () {
      if (!this.validURL) return
      try {
        window.open(this.value, '_blank')
      } catch (error) {}
    },
    urlValidity () {
      return this.value && (this.$refs?.URLInput as any)?.$el.validity?.valid
    },
  },
  watch: {
    value () {
      this.validURL = this.urlValidity()
    },
  },
})
