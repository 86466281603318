


















































import Vue from 'vue'

import { ValidationProvider } from 'vee-validate'

import InputText from 'primevue/inputtext'
import ToggleButton from 'primevue/togglebutton'

import DialogForm from '@/components/ui/DialogForm/DialogForm.vue'

export default Vue.extend({
  name: 'ViewDialog',
  components: {
    'lck-dialog-form': DialogForm,
    'p-input-text': Vue.extend(InputText),
    'p-toggle-button': Vue.extend(ToggleButton),
    'validation-provider': Vue.extend(ValidationProvider),
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data () {
    return {
      currentData: {},
    }
  },
  watch: {
    value: {
      handler (newValue: {}) {
        this.currentData = {
          ...newValue,
        }
      },
      immediate: true,
    },
  },
})
