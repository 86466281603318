











































































































import Vue from 'vue'

import { ValidationProvider } from 'vee-validate'

import {
  EXTENDED_NAMED_CLASSES,
  TEXT_ALIGN_CLASS,
} from '@/services/lck-utils/prime'
import { LckTableView } from '@/services/lck-api/definitions'

import InputText from 'primevue/inputtext'
import Dropdown from 'primevue/dropdown'

import AutoComplete from '@/components/ui/AutoComplete/AutoComplete.vue'

export default Vue.extend({
  name: 'MarkdownFieldSettingsFields',
  components: {
    'lck-autocomplete': AutoComplete,
    'p-input-text': Vue.extend(InputText),
    'p-dropdown': Vue.extend(Dropdown),
    'validation-provider': Vue.extend(ValidationProvider),
  },
  props: {
    id: {
      type: String,
    },
    displayFieldId: {
      type: String,
    },
    textColor: {
      type: String,
    },
    textAlign: {
      type: String,
    },
    tableViewDefinition: {
      type: Object as Vue.PropType<LckTableView | null>,
    },
    autocompleteSuggestions: {
      type: Array,
      default: () => ([]),
    } as Vue.PropOptions<{ label: string; value: string }[]>,
  },
  data () {
    return {
      EXTENDED_NAMED_CLASSES,
      TEXT_ALIGN_CLASS,
      tableView: null as { value: string; text: string } | null,
    }
  },
  methods: {
    onChangeTableView () {
      this.$emit('update:id', this.tableView?.value)
      this.$emit('component-refresh-required', true)
    },
  },
  watch: {
    tableViewDefinition: {
      handler (view: LckTableView | null) {
        if (view) {
          this.tableView = {
            value: view.id,
            text: view.text,
          }
        }
      },
      immediate: true,
    },
  },
})
