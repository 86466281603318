





















































































import Vue from 'vue'

import { ValidationProvider } from 'vee-validate'

import { EXTENDED_NAMED_CLASSES, TEXT_ALIGN_CLASS } from '@/services/lck-utils/prime'

import Textarea from 'primevue/textarea'
import Dropdown from 'primevue/dropdown'

export default Vue.extend({
  name: 'MarkdownSettingsFields',
  components: {
    'p-textarea': Vue.extend(Textarea),
    'p-dropdown': Vue.extend(Dropdown),
    'validation-provider': Vue.extend(ValidationProvider),
  },
  props: {
    content: {
      type: String,
    },
    textColor: {
      type: String,
    },
    textAlign: {
      type: String,
    },
  },
  data () {
    return {
      EXTENDED_NAMED_CLASSES,
      TEXT_ALIGN_CLASS,
    }
  },
})
