















































import { ROUTES_PATH } from '@/router/paths'
import { lckHelpers } from '@/services/lck-api'
import { LckWorkspace } from '@/services/lck-api/definitions'
import Vue, { PropOptions } from 'vue'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import Skeleton from 'primevue/skeleton'
import { TranslateResult } from 'vue-i18n'

export default Vue.extend({
  name: 'DatabaseList',
  components: {
    'p-accordion': Vue.extend(Accordion),
    'p-accordion-tab': Vue.extend(AccordionTab),
    'p-skeleton': Vue.extend(Skeleton),
  },
  props: {
    workspaceId: {
      type: String,
      required: true,
    } as PropOptions<string>,
    sidebarActive: {
      type: Boolean,
      default: true,
    } as PropOptions<boolean>,
  },
  data (): {
    loading: boolean;
    workspace: LckWorkspace | null;
    } {
    return {
      loading: false,
      workspace: null,
    }
  },
  mounted () {
    this.getDatabaseList()
  },
  computed: {
    menuItems (): Array<{
      id: string;
      label: string;
      items: Array<{ id: string; label: string | TranslateResult; icon: string; to: string }>;
    }> | undefined {
      if (!this.workspace) return []
      return this.workspace.databases?.map(database => ({
        id: database.id,
        label: database.text,
        items: [
          {
            id: 'schema',
            label: this.$t('pages.workspace.menu.schema'),
            icon: 'bi-diagram-2',
            to: `${ROUTES_PATH.WORKSPACE}/${this.workspaceId}${ROUTES_PATH.ADMIN}${ROUTES_PATH.DATABASE}/${database.id}${ROUTES_PATH.DATABASESCHEMA}`,
          },
          ...database.tables.map(table => ({
            id: table.id,
            label: table.text,
            icon: 'bi-table',
            to: `${ROUTES_PATH.WORKSPACE}/${this.workspaceId}${ROUTES_PATH.ADMIN}${ROUTES_PATH.DATABASE}/${database.id}${ROUTES_PATH.DATABASETABLE}/${table.id}`,
          })),
        ],
      }))
    },
  },
  methods: {
    async getDatabaseList () {
      this.loading = true
      this.workspace = await lckHelpers.retrieveWorkspaceWithDatabases(this.workspaceId)
      this.loading = false
    },
  },
})
