



































import Vue from 'vue'

import ProgressSpinner from 'primevue/progressspinner'

import LayoutWithBackground from '@/layouts/WithBackground.vue'

import { lckClient } from '@/services/lck-api'
import { logout } from '@/store/auth'

export default Vue.extend({
  name: 'UpdateEmail',
  data () {
    return {
      loading: false,
      updateOK: false,
    }
  },
  components: {
    'layout-with-background': Vue.extend(LayoutWithBackground),
    'p-progress-spinner': Vue.extend(ProgressSpinner),
  },
  async mounted () {
    this.loading = true
    try {
      await lckClient.service('authManagement')
        .create({
          action: 'verifySignupLong',
          value: this.$route.query?.token,
        })
      this.updateOK = true
      logout()
    } catch (error) {
      this.updateOK = false
    } finally {
      this.loading = false
    }
  },
})

