























































































































































































































import Vue from 'vue'

import { ValidationProvider } from 'vee-validate'

import { BLOCK_TYPE, MapSettings, MapSourceSettings, MediaSettings, MEDIA_TYPE } from '@locokit/lck-glossary'
import { LckBlockExtended, LckTableView, MediaConfiguration } from '@/services/lck-api/definitions'

import InputText from 'primevue/inputtext'
import Dropdown from 'primevue/dropdown'
import InputSwitch from 'primevue/inputswitch'

import LckForm from '@/components/ui/Form/Form.vue'
import ParagraphSettingsFields from '@/components/visualize/UpdateSidebar/UpdateBlockForm/BlockSettingsFields/ParagraphSettingsFields.vue'
import MarkdownSettingsFields from '@/components/visualize/UpdateSidebar/UpdateBlockForm/BlockSettingsFields/MarkdownSettingsFields.vue'
import MarkdownFieldSettingsFields from '@/components/visualize/UpdateSidebar/UpdateBlockForm/BlockSettingsFields/MarkdownFieldSettingsFields.vue'
import MediaSettingsFields from '@/components/visualize/UpdateSidebar/UpdateBlockForm/BlockSettingsFields/MediaSettingsFields.vue'
import TableSetSettingsFields from '@/components/visualize/UpdateSidebar/UpdateBlockForm/BlockSettingsFields/TableSetSettingsFields.vue'
import DataRecordSettingsFields from '@/components/visualize/UpdateSidebar/UpdateBlockForm/BlockSettingsFields/DataRecordSettingsFields.vue'
import MapSettingsFields from '@/components/visualize/UpdateSidebar/UpdateBlockForm/BlockSettingsFields/MapSettingsFields.vue'
import ActionButtonSettingsFields from '@/components/visualize/UpdateSidebar/UpdateBlockForm/BlockSettingsFields/ActionButtonSettingsFields.vue'
import AutoComplete from '@/components/ui/AutoComplete/AutoComplete.vue'

interface PopupContentField {
  field: string;
  class?: string;
}

export default Vue.extend({
  name: 'UpdateBlockForm',
  components: {
    'lck-form': LckForm,
    'lck-autocomplete': AutoComplete,
    'paragraph-settings-fields': ParagraphSettingsFields,
    'markdown-settings-fields': MarkdownSettingsFields,
    'markdown-field-settings-fields': MarkdownFieldSettingsFields,
    'media-settings-fields': MediaSettingsFields,
    'table-set-settings-fields': TableSetSettingsFields,
    'data-record-settings-fields': DataRecordSettingsFields,
    'map-settings-fields': MapSettingsFields,
    'action-button-settings-fields': ActionButtonSettingsFields,
    'p-input-text': Vue.extend(InputText),
    'p-switch': Vue.extend(InputSwitch),
    'p-dropdown': Vue.extend(Dropdown),
    'validation-provider': Vue.extend(ValidationProvider),
  },
  props: {
    block: {
      type: Object as Vue.PropType<LckBlockExtended>,
      required: true,
    },
    submitting: {
      type: Boolean,
      default: false,
    },
    autocompleteSuggestions: {
      type: Array,
      default: () => ([]),
    } as Vue.PropOptions<{ label: string; value: string }[]>,
    blockDisplayTableViewSuggestions: {
      type: Array,
      default: () => ([]),
    } as Vue.PropOptions<{ label: string; value: string }[]>,
    blockDisplayFieldSuggestions: {
      type: Array,
      default: () => ([]),
    } as Vue.PropOptions<{ label: string; value: string }[]>,
    relatedChapterPages: {
      type: Array,
      default: () => ([]),
    },
  },
  data () {
    return {
      BLOCK_TYPE,
      blockCopy: new LckBlockExtended(),
      tableViewDefinition: null as Record<string, LckTableView> | LckTableView | null,
      blockRefreshRequired: false,
      blockDisplayTableView: null as { text: string; value: string } | null,
      blockDisplayField: null as { text: string; value: string } | null,
    }
  },
  computed: {
    blockTypesValues () {
      return Object.values(BLOCK_TYPE)
    },
  },
  methods: {
    resetBlockSettings (blockType: string) {
      const defaultSettings = {}
      switch (blockType) {
        case BLOCK_TYPE.MEDIA:
          (defaultSettings as MediaSettings).medias = []
          break
        case BLOCK_TYPE.MAP_SET:
        case BLOCK_TYPE.MAP_FIELD:
          (defaultSettings as MapSettings).sources = []
          break
      }
      this.$set(this.blockCopy, 'settings', defaultSettings)
    },
    onFormSubmit () {
      this.$emit('input', {
        blockToEdit: this.blockCopy,
        blockRefreshRequired: this.blockRefreshRequired,
      })
      this.blockRefreshRequired = false
    },
    onComponentRefreshRequired (refreshRequired: boolean) {
      this.blockRefreshRequired = refreshRequired
    },
    // Manage the media block
    onUpdateMediaName ({ media, name }: { media: MediaConfiguration; name: string }) {
      media.name = name
    },
    onUpdateMediaSrcURL ({ media, srcURL }: { media: MediaConfiguration; srcURL: string }) {
      media.srcURL = srcURL
    },
    onUpdateMediaType ({ media, type }: { media: MediaConfiguration; type: MEDIA_TYPE.IMAGE | MEDIA_TYPE.VIDEO }) {
      media.type = type
    },
    onAddMedia () {
      (this.blockCopy.settings as MediaSettings).medias.push({
        name: '',
        srcURL: '',
        type: MEDIA_TYPE.IMAGE,
      })
    },
    onUpdateMediaDisplayMode (displayMode: MEDIA_TYPE) {
      if (displayMode === MEDIA_TYPE.IMAGE || displayMode === MEDIA_TYPE.VIDEO) {
        (this.blockCopy.settings as MediaSettings).medias.splice(1)
      }
    },
    onDeleteMedia (index: number) {
      (this.blockCopy.settings as MediaSettings).medias.splice(index, 1)
    },
    // Manage the map block
    onAddMapSource () {
      (this.blockCopy.settings as MapSettings).sources.push({
        id: '',
      })
    },
    onDeleteMapSource (index: number) {
      (this.blockCopy.settings as MapSettings).sources.splice(index, 1)
    },
    onUpdateMapSourceId ({ index, id }: { index: number; id: string | null }) {
      (this.blockCopy.settings as MapSettings).sources.splice(index, 1, {
        id: id || '',
      })
    },
    onUpdateMapSourceProperty ({ source, propertyName, propertyValue }: {
      source: MapSourceSettings;
      propertyName: string;
      propertyValue: string | boolean;
    }) {
      this.$set(source, propertyName, propertyValue)
    },
    onUpdateMapSourcePopupProperty ({ source, propertyName, propertyValue }: {
      source: MapSourceSettings;
      propertyName: string;
      propertyValue: string | boolean;
    }) {
      if (source.popupSettings) {
        this.$set(source.popupSettings, propertyName, propertyValue)
      } else {
        this.$set(source, 'popupSettings', { [propertyName]: propertyValue })
      }
    },
    onAddMapSourcePopupContent (popupSettings: { contentFields?: PopupContentField[]}) {
      if (!popupSettings.contentFields) {
        this.$set(popupSettings, 'contentFields', [{
          field: '',
        }])
      } else {
        popupSettings.contentFields.push({
          field: '',
        })
      }
    },
    onDeleteMapSourcePopupContent ({ contentFields, index }: { contentFields: PopupContentField[]; index: number }) {
      contentFields.splice(index, 1)
    },
    onUpdateMapSourcePopupContentProperty ({ content, propertyName, propertyValue }: {
      content: PopupContentField;
      propertyName: string;
      propertyValue: string | null;
    }) {
      this.$set(content, propertyName, propertyValue)
    },
  },
  watch: {
    block: {
      handler (newValue: LckBlockExtended) {
        this.blockCopy = { ...newValue }
        this.tableViewDefinition = newValue.definition ? newValue.definition : null
        delete this.blockCopy.displayTableView
        delete this.blockCopy.displayField
        delete this.blockCopy.loading
        delete this.blockCopy.pageLoaded
        delete this.blockCopy.createdAt
        delete this.blockCopy.updatedAt
        delete this.blockCopy.definition
        this.blockDisplayTableView = null
        this.blockDisplayField = null
        if (newValue.displayTableView) {
          this.blockDisplayTableView = {
            text: newValue.displayTableView.text,
            value: newValue.displayTableView.id,
          }
        }
        if (newValue.displayField) {
          this.blockDisplayField = {
            text: newValue.displayField.text,
            value: newValue.displayField.id,
          }
        }
        if (!this.blockCopy.settings) this.resetBlockSettings(this.blockCopy.type)
      },
      immediate: true,
    },
  },
})
