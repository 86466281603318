





















import Vue, { PropType } from 'vue'
import { MediaSettings } from '@locokit/lck-glossary'

export default Vue.extend({
  name: 'Media',
  props: {
    settings: {
      type: Object as PropType<MediaSettings>,
      default: () => ({}),
    },
  },
})
