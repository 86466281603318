










































































































































































import Vue, { PropOptions } from 'vue'

import { LckWorkspace } from '@/services/lck-api/definitions'
import Form from '@/components/ui/Form/Form.vue'
import { ColorScheme, COLOR_SCHEME } from '@/services/lck-utils/color'
import InputText from 'primevue/inputtext'
import Textarea from 'primevue/textarea'
import Dropdown from 'primevue/dropdown'
import Checkbox from 'primevue/checkbox'
import { ValidationProvider } from 'vee-validate'

import Badge from '@/components/ui/Badge/Badge.vue'

export default Vue.extend({
  name: 'WorkspaceForm',
  components: {
    'lck-form': Form,
    'lck-badge': Vue.extend(Badge),
    'p-dropdown': Vue.extend(Dropdown),
    'p-input-text': Vue.extend(InputText),
    'p-textarea': Vue.extend(Textarea),
    'p-checkbox': Vue.extend(Checkbox),
    'validation-provider': Vue.extend(ValidationProvider),
  },
  props: {
    submitting: {
      type: Boolean,
      default: false,
    },
    workspace: {
      type: Object,
      required: false,
    } as PropOptions<LckWorkspace>,
  },
  data () {
    return {
      newWorkspace: new LckWorkspace(),
      colorScheme: COLOR_SCHEME,
      newWorkspaceColorScheme: null as ColorScheme | null | undefined,
    }
  },
  methods: {
    onColorSelect (event: { value: ColorScheme | null}) {
      this.newWorkspaceColorScheme = event.value
      if (event.value) {
        if (!this.newWorkspace.settings) {
          this.newWorkspace.settings = {}
        }
        this.newWorkspace.settings.color = event.value.color
        this.newWorkspace.settings.backgroundColor = event.value.backgroundColor
      } else {
        if (this.newWorkspace.settings) {
          this.newWorkspace.settings.color = null
          this.newWorkspace.settings.backgroundColor = null
        }
      }
    },
  },
  watch: {
    workspace: {
      handler (newValue: LckWorkspace) {
        if (!newValue) return
        this.newWorkspace = {
          ...newValue,
        }
        this.newWorkspaceColorScheme = COLOR_SCHEME.find(c => c.backgroundColor === this.newWorkspace.settings?.backgroundColor)
      },
      immediate: true,
    },
  },
})
