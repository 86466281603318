



































import {
  authState,
  authenticate,
} from '@/store/auth'
import { appState } from '@/store/app'
import Login from '@/components/auth/Login/Login.vue'
import Dialog from './components/ui/Dialog/Dialog.vue'
import PopupReload from '@/components/ui/PopupReload/PopupReload.vue'
import Toast from 'primevue/toast'
import Vue from 'vue'
import { PrimeVueLocaleOptions } from 'primevue/config/PrimeVue'

export default Vue.extend({
  name: 'app',
  components: {
    'lck-dialog': Dialog,
    'lck-login': Login,
    'lck-popup-reload': PopupReload,
    'p-toast': Toast,
  },
  data () {
    return {
      // keep it here in the data to make it reactive
      authState,
      displayPopupReload: false,
      appState,
    }
  },
  computed: {
    displayHeader (): boolean {
      let result = true
      if (this.$route?.meta?.needHeader !== undefined) {
        result = this.$route.meta.needHeader
      }
      return result
    },
  },
  methods: {
    authenticate,
  },
  created () {
    // Listen for swUpdated event and display refresh modal.
    document.addEventListener('swUpdated', () => {
      this.displayPopupReload = true
    }, { once: true })
  },
  watch: {
    '$i18n.locale': {
      handler () {
        this.$primevue.config.locale = this.$t('localePrime') as unknown as PrimeVueLocaleOptions
      },
      immediate: true,
    },
  },

})
