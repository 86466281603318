












































































































/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import Vue, { PropOptions } from 'vue'

import { lckServices, lckHelpers } from '@/services/lck-api'
import { LckAttachment } from '@/services/lck-api/definitions'
import { getAttachmentsToDisplay } from '@/components/ui/ColumnType/File/helpers'
import LckAsyncImage from '@/components/ui/AsyncImage/AsyncImage.vue'

import Card from 'primevue/card'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import ConfirmDialog from 'primevue/confirmdialog'

import { Paginated } from '@feathersjs/feathers'

export default Vue.extend({
  name: 'WorkspaceFiles',
  components: {
    'p-card': Vue.extend(Card),
    'p-datatable': Vue.extend(DataTable),
    'p-column': Vue.extend(Column),
    'p-button': Vue.extend(Button),
    'p-inputtext': Vue.extend(InputText),
    'p-confirmdialog': Vue.extend(ConfirmDialog),
    LckAsyncImage,
  },
  props: {
    workspaceId: {
      type: String,
      required: true,
    } as PropOptions<string>,
  },
  data () {
    return {
      loading: false,
      attachments: [],
      limit: 10,
      page: 0,
      total: 0,
      filterName: '',
      sortField: 'filename',
      sortOrder: 1,
    } as {
      loading: boolean;
      attachments: LckAttachment[];
      limit: 10 | 20 | 50;
      page: number;
      total: number;
      filterName: string;
      sortField: string;
      sortOrder: number;
    }
  },
  async mounted () {
    await this.fetchAttachments()
  },
  methods: {
    /**
     * Fetch the workspace.
     */
    async fetchAttachments () {
      this.loading = true
      try {
        const queryParams: {
          query: Record<string, any>;
        } = {
          query: {
            workspace_id: this.workspaceId,
            $skip: this.page * this.limit,
            $limit: this.limit,
            $sort: { [this.sortField]: this.sortOrder },
          },
        }
        if (this.filterName) queryParams.query.filename = { $ilike: `%${this.filterName}%` }
        const result = await lckServices.attachment.find(queryParams) as Paginated<LckAttachment>
        this.total = result.total
        this.attachments = getAttachmentsToDisplay(result.data, this.workspaceId)
      } catch (error) {
        this.displayToastOnError(error as any)
      }
      this.loading = false
    },
    async onPage (event: {page: number }) {
      this.page = event.page
      this.fetchAttachments()
    },
    async onSort (event: { sortField: string; sortOrder: number }) {
      this.sortField = event.sortField
      this.sortOrder = event.sortOrder
      this.fetchAttachments()
    },
    /**
     * Display an error toast whose the content is based on the error code.
     */
    displayToastOnError (error: Error & { code: number }) {
      this.$toast.add({
        severity: 'error',
        summary: this.$t('error.basic'),
        detail: this.$t('error.http.' + error.code),
        life: 5000,
      })
    },

    downloadAttachment: lckHelpers.downloadAttachment,

    removeAttachment (attachment: LckAttachment) {
      this.$confirm.require({
        message: this.$t('pages.workspaceAdmin.files.removeConfirmationDialog.message', { file: attachment.filename }),
        header: this.$t('pages.workspaceAdmin.files.removeConfirmationDialog.header'),
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          try {
            await lckServices.attachment.remove(attachment.id)
            this.$toast.add({
              severity: 'success',
              summary: this.$t('pages.workspaceAdmin.files.removeSuccessToast.summary', { file: attachment.filename }),
              life: 3000,
            })
            this.fetchAttachments()
          } catch (error) {
            this.$toast.add({
              severity: 'error',
              summary: this.$t('pages.workspaceAdmin.files.removeErrorToast.summary', { file: attachment.filename }),
              detail: this.$t('pages.workspaceAdmin.files.removeErrorToast.detail', { error }),
            })
          }
        },
        reject: () => {
          this.$confirm.close()
        },
      })
    },
  },
})
