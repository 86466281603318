var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"LckCellFile",on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }$event.stopPropagation();$event.preventDefault();_vm.dialogFileVisible = true},"contextmenu":function($event){$event.stopPropagation();$event.preventDefault();}}},[_vm._l((_vm.attachmentsToDisplay),function(attachment,attachmentIndex){return [(attachment.element === 'img')?_c('lck-async-image',{key:attachment.id,staticClass:"cell-file",attrs:{"title":'(' + (attachmentIndex + 1) + '/' + _vm.attachmentsToDisplay.length + ') ' + attachment.filename,"src":attachment.thumbnailURL},on:{"click":function($event){return _vm.$emit('download', {
        url: attachment.url,
        filename: attachment.filename,
        mime: attachment.mime
      })}}}):_c('span',{key:attachment.id,staticClass:"cell-file",class:attachment.class,attrs:{"title":'(' + (attachmentIndex + 1) + '/' + _vm.attachmentsToDisplay.length + ') ' + attachment.filename},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('download', {
        url: attachment.url,
        filename: attachment.filename,
        mime: attachment.mime
      })}}})]}),_c('lck-cell-file-dialog',{staticClass:"field-editable",attrs:{"attachments":_vm.attachments,"workspaceId":_vm.workspaceId,"title":_vm.title,"visible":_vm.dialogFileVisible,"disabled":_vm.disabled},on:{"input":function($event){return _vm.$emit('input', $event)},"close":function($event){_vm.dialogFileVisible = false},"download":function($event){return _vm.$emit('download', $event)},"remove-attachment":function($event){return _vm.$emit('remove-attachment', $event)}}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }