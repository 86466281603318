


























import Vue from 'vue'
import Dialog from 'primevue/dialog'

export default Vue.extend({
  name: 'LckDialog',
  components: {
    'p-dialog': Vue.extend(Dialog),
  },
  props: {
    header: {
      type: String,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    contentStyle: {
      type: Object,
    },
    baseZIndex: {
      type: Number,
      default: 0,
    },
    closable: {
      type: Boolean,
      default: true,
    },
    closeOnEscape: {
      type: Boolean,
      default: true,
    },
  },
})
