













import Vue, { PropOptions } from 'vue'

import { LckTableRow } from '@/services/lck-api/definitions'
import {
  ACTION_BUTTON_TYPE,
  ActionButtonSettings,
} from '@locokit/lck-glossary'

import Button from 'primevue/button'

export default Vue.extend({
  name: 'ActionButton',
  components: {
    'p-button': Vue.extend(Button),
  },
  props: {
    displayCheckIcon: {
      type: Boolean,
      default: false,
    } as PropOptions<boolean>,
    content: {
      type: Object,
    } as PropOptions<{ data: LckTableRow[] | LckTableRow }>,
    settings: {
      type: Object,
      required: true,
    } as PropOptions<ActionButtonSettings>,
    loading: {
      type: Boolean,
      default: false,
    } as PropOptions<boolean>,
  },
  computed: {
    row () {
      if (this.content && Array.isArray(this.content.data)) {
        // Case for ActionButton block
        return this.content.data[0]
      }
      // Case for ActionButton in DataTable
      return this.content
    },
    isHidden (): boolean {
      if (!this.settings?.displayFieldId) return false
      return (this.row as LckTableRow)?.data[this.settings.displayFieldId as string] !== (this.settings.displayFieldConditionQuery === 'true')
    },
  },
  methods: {
    onClick (settings: ActionButtonSettings) {
      switch (settings.action) {
        case ACTION_BUTTON_TYPE.PAGE_DETAIL_TO:
          this.$emit('go-to-page-detail', {
            pageDetailId: settings.pageDetailId,
            pageQueryFieldId: settings.pageQueryFieldId,
            rowData: this.row,
          })
          break
        case ACTION_BUTTON_TYPE.PROCESS_TRIGGER:
          this.$emit('create-process-run', {
            processId: settings.processId,
            typePageTo: settings.typePageTo,
            pageRedirectId: settings.pageRedirectId,
            pageQueryFieldId: settings.pageQueryFieldId,
            notificationSuccessTitle: settings.notificationSuccessTitle,
            notificationSuccessDescription: settings.notificationSuccessDescription,
            notificationErrorTitle: settings.notificationErrorTitle,
            notificationErrorDescription: settings.notificationErrorDescription,
            rowData: this.row,
          })
          break
      }
    },
  },
})
