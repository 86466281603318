

























































































































import Vue from 'vue'
import Button from 'primevue/button'
import InputSwitch from 'primevue/inputswitch'
import Panel from 'primevue/panel'
import PrimeDataTable from 'primevue/datatable'
import Column from 'primevue/column'

import { getDisabledProcessTrigger } from '@/services/lck-utils/process'
import { formatDateString } from '@/services/lck-utils/date'
import { PROCESS_RUN_STATUS, PROCESS_TRIGGER } from '@/services/lck-api/definitions'

export default Vue.extend({
  name: 'ProcessPanel',
  components: {
    'p-input-switch': Vue.extend(InputSwitch),
    'p-panel': Vue.extend(Panel),
    'p-datatable': Vue.extend(PrimeDataTable),
    'p-column': Vue.extend(Column),
    'p-button': Vue.extend(Button),
  },
  props: {
    processesByRow: {
      type: Array,
      default: () => ([]),
    },
    rowId: {
      type: String,
      default: null,
    },
  },
  data () {
    return {
      PROCESS_RUN_STATUS,
      PROCESS_TRIGGER,
      expandedRows: [],
    }
  },
  methods: {
    formatDateString,
    getDisabledProcessTrigger,
    onProcessTrigger (rowId: string, process: {id: string; text: string }) {
      this.$emit('create-process-run', {
        rowId,
        processId: process.id,
        name: process.text,
      })
    },
    onActivityProcess (processId: string, event: boolean) {
      this.$emit('toggle-process', {
        processId,
        enabled: event,
      })
    },
  },
})
