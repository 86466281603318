









import Vue from 'vue'

export default Vue.extend({
  name: 'LckBadge',
  props: {
    label: String, color: String, backgroundColor: String,
  },
})

