






















































































import Vue from 'vue'
import { ROUTES_PATH, ROUTES_NAMES } from '@/router/paths'
import { AuthState, authState } from '@/store/auth'
import { ColorScheme, COLOR_SCHEME } from '@/services/lck-utils/color'

import Skeleton from 'primevue/skeleton'

import WorkspaceForm from '@/components/visualize/WorkspaceForm/WorkspaceForm.vue'
import Dialog from '@/components/ui/Dialog/Dialog.vue'

import { lckServices } from '@/services/lck-api'
import { LckDatabase, LckWorkspace } from '@/services/lck-api/definitions'
import WithHeader from '@/layouts/WithHeader.vue'

const WORKSPACE_ROLE = {
  OWNER: 'OWNER',
  ADMIN: 'ADMIN',
  MEMBER: 'MEMBER',
}

export default Vue.extend({
  name: 'WorkspaceList',
  components: {
    'layout-with-header': WithHeader,
    'lck-workspace-form': WorkspaceForm,
    'lck-dialog': Dialog,
    'p-skeleton': Vue.extend(Skeleton),
  },
  data (): {
    loading: boolean;
    submitting: boolean;
    dialogVisible: boolean;
    ROUTES_PATH: typeof ROUTES_PATH;
    ROUTES_NAMES: typeof ROUTES_NAMES;
    authState: AuthState;
    colorScheme: ColorScheme[];
    WORKSPACE_ROLE: typeof WORKSPACE_ROLE;
    newWorkspace: Partial<LckWorkspace>;
    newWorkspaceColorScheme: ColorScheme | null;
    workspaces: {
      id: string;
      text: string;
      documentation?: string;
      color?: string | null;
      backgroundColor?: string | null;
      icon?: string | null;
      isManager: boolean;
    }[];
    } {
    return {
      loading: false,
      submitting: false,
      dialogVisible: false,
      ROUTES_PATH,
      ROUTES_NAMES,
      authState,
      WORKSPACE_ROLE,
      colorScheme: COLOR_SCHEME,
      newWorkspace: {
        text: '',
        documentation: '',
        settings: {},
      },
      newWorkspaceColorScheme: null,
      workspaces: [],
    }
  },
  methods: {
    transformDatabases (groupId: string, databases: LckDatabase[], schema = false) {
      return databases.map(({ text, id }) => ({
        id,
        label: text,
        to: `${ROUTES_PATH.WORKSPACE}/${groupId}${ROUTES_PATH.DATABASE}/${id}${schema ? ROUTES_PATH.DATABASESCHEMA : ''}`,
      }))
    },
    onColorSelect (event: { value: ColorScheme | null}) {
      this.newWorkspaceColorScheme = event.value
      if (event.value) {
        if (!this.newWorkspace.settings) {
          this.newWorkspace.settings = {}
        }
        this.newWorkspace.settings.color = event.value.color
        this.newWorkspace.settings.backgroundColor = event.value.backgroundColor
      }
    },
    async createWorkspace (newWorkspace: LckWorkspace) {
      this.submitting = true
      try {
        await lckServices.workspace.create(newWorkspace)
        this.$toast.add({
          severity: 'success',
          summary: this.$t('pages.workspace.form.createdSummary'),
          detail: this.$t('pages.workspace.form.createdDetail'),
          life: 5000,
        })
        this.dialogVisible = false
        this.fetchUserGroups()
      } catch (error) {
        console.error(error)
        this.$toast.add({
          severity: 'error',
          summary: this.$t('error.http.' + error.code),
          detail: this.$t('error.lck.' + error.data?.code),
          life: 5000,
        })
      }
      this.submitting = false
    },
    async fetchUserGroups () {
      this.loading = true
      const userWorkspaces = await lckServices.workspace.find({
        query: {
          $modify: 'ofUser',
          $limit: -1,
        },
      }) as LckWorkspace[]
      this.workspaces = userWorkspaces.map((w: LckWorkspace) => {
        const currentWorkspace = {
          id: w.id,
          text: w.text,
          documentation: w.documentation,
          icon: w.settings?.icon,
          color: w.settings?.color,
          backgroundColor: w.settings?.backgroundColor,
          isManager: false,
        }
        // eslint-disable-next-line no-unused-expressions
        w.aclsets?.forEach(function (aclset) {
          if (aclset.manager) {
            currentWorkspace.isManager = true
          }
        })
        return currentWorkspace
      })
      this.loading = false
    },
  },
  mounted () {
    this.fetchUserGroups()
  },
  /**
   * This hook is executed each time the WorkspaceList is matched by the vue-router
   * So, sometimes, we don't want this hook execute
   * => we check before executing we are on the "good" component...
   */
  async beforeRouteEnter (to, from, next) {
    if (to.name !== ROUTES_NAMES.WORKSPACELIST) next()
    const userWorkspacesAvailable = authState?.data?.user?.groups
    if (
      !userWorkspacesAvailable?.some(({ aclset }) => aclset?.manager) &&
      userWorkspacesAvailable?.length === 1
    ) {
      // only one workspace, user is not a member of a group-aclset manager
      // we redirect user on the visualization route
      authState.data.currentGroupId = userWorkspacesAvailable[0].id
      next({
        name: ROUTES_NAMES.WORKSPACE,
        params: {
          workspaceId: userWorkspacesAvailable[0].aclset?.workspace_id as string,
          groupId: authState.data.currentGroupId,
        },
      })
    } else {
      next()
    }
  },
})
