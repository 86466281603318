





















































import Vue from 'vue'

import InputText from 'primevue/inputtext'
import { ValidationProvider } from 'vee-validate'

import LckForm from '@/components/ui/Form/Form.vue'

export default Vue.extend({
  name: 'LckSignUp',
  props: {
    error: {
      type: Error,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data (): {
    user: {
      email: string;
      name: string;
    };
    } {
    return {
      user: {
        email: '',
        name: '',
      },
    }
  },
  components: {
    'p-input-text': Vue.extend(InputText),
    'validation-provider': Vue.extend(ValidationProvider),
    'lck-form': LckForm,
  },
})
