




































































































































































import Vue, { PropOptions } from 'vue'
import Dropdown from 'primevue/dropdown'
import Button from 'primevue/button'
import Calendar from 'primevue/calendar'
import InputText from 'primevue/inputtext'
import InputNumber from 'primevue/inputnumber'
import MultiSelect from '@/components/ui/MultiSelect/MultiSelect.vue'
import OverlayPanel from '@/components/ui/OverlayPanel/OverlayPanel.vue'

import { COLUMN_TYPE } from '@locokit/lck-glossary'
import { getComponentEditorCellForColumnType, getColumnTypeId } from '@/services/lck-utils/columns'
import { LckTableViewColumn } from '@/services/lck-api/definitions'
import { Filter, FilterAction, OPERATORS, COLUMN_FILTERS_CONFIG } from '@/services/lck-utils/filter'

export default Vue.extend({
  name: 'LckFilterButton',
  components: {
    'p-dropdown': Vue.extend(Dropdown),
    'p-input-text': Vue.extend(InputText),
    'p-input-number': Vue.extend(InputNumber),
    'p-calendar': Vue.extend(Calendar),
    'p-button': Vue.extend(Button),
    'lck-multiselect': MultiSelect,
    'lck-overlaypanel': Vue.extend(OverlayPanel),
  },
  props: {
    definition: {
      type: Object,
      required: false,
      default: () => ({
        columns: [],
      }),
    } as PropOptions<{
      columns: LckTableViewColumn[];
    }>,
    columnsDropdownOptions: {
      type: Object,
      required: false,
      default: () => ({}),
    } as PropOptions<Record<string, {
        value: string;
        label: string;
      }>>,
    value: {
      type: Array,
      required: false,
      default: () => ([]),
    } as PropOptions<Filter[]>,
    disabled: {
      type: Boolean,
      default: false,
    },
    locked: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      columnFiltersConfig: COLUMN_FILTERS_CONFIG,
      operators: OPERATORS,
      selectedOperator: OPERATORS[0].value,
      hasChanged: false,
    }
  },
  computed: {
    supportedColumns (): { value: string; label: string; type: COLUMN_TYPE; originalType: COLUMN_TYPE }[] {
      if (!this.definition?.columns?.length) return []
      return this.definition.columns.reduce((acc, column) => {
        const originalType = getColumnTypeId(column)
        if (column.column_type_id !== COLUMN_TYPE.VIRTUAL_LOOKED_UP_COLUMN && COLUMN_FILTERS_CONFIG[originalType]) {
          acc.push({
            value: column.id,
            label: column.text,
            type: column.column_type_id,
            originalType,
          })
          /**
           * So, the user could filter unsupported types if the user create a LUC on a unsupported field.
           * That's a nice feature :-) (joke !)
           * TODO https://gitlab.makina-corpus.net/lck/lck-front/-/issues/406
           */
        } else if (column.column_type_id === COLUMN_TYPE.LOOKED_UP_COLUMN) {
          acc.push({
            value: column.id,
            label: column.text,
            type: COLUMN_TYPE.LOOKED_UP_COLUMN,
            originalType: COLUMN_TYPE.LOOKED_UP_COLUMN,
          })
        }
        return acc
      }, [] as {
        value: string;
        label: string;
        type: COLUMN_TYPE;
        originalType: COLUMN_TYPE;
      }[])
    },
    invalidFilters (): boolean {
      return this.value.some(
        ({ column, action, pattern }) => (column === null) || (action === null) || (pattern === null),
      )
    },
    canSaveFilters (): boolean {
      return !this.locked && !this.invalidFilters && (this.value.length > 0 || this.hasChanged)
    },
  },
  methods: {
    getComponentEditorCellForColumnType,
    removeFilter (filterToRemove: Filter) {
      this.$emit('input', this.value.filter(f => (f !== filterToRemove)))
    },
    resetFilters () {
      this.$emit('reset')
    },
    submitFilters (overlaySlotProps: { toggleOverlayPanel: Function }) {
      this.$emit('submit')
      overlaySlotProps.toggleOverlayPanel()
    },
    saveFilters () {
      this.$emit('save-filters', this.hasChanged)
      this.hasChanged = !this.hasChanged
    },
    addFilter () {
      this.value.push({
        operator: this.selectedOperator,
        column: null,
        action: null,
        pattern: null,
      })
    },
    onChangeOperator (event: { value: string }) {
      this.selectedOperator = event.value
      if (this.value.length > 1) {
        this.value.forEach(filter => (filter.operator = this.selectedOperator))
      }
    },
    onChangeColumn (index: number) {
      this.value[index].action = null
      this.value[index].pattern = null
    },
    actionControlPattern (index: number, { value }: { value: FilterAction }) {
      if (value?.predefinedPattern !== undefined) {
        // Set the pattern if the selected action has a predefined one
        this.value[index].pattern = value.predefinedPattern
      } else {
        if (this.value[index].action?.predefinedPattern) {
          // Reset the pattern if the previous action had a predefined one
          this.value[index].pattern = null
        }
      }
      // Set the action
      this.value[index].action = value
    },
  },
  watch: {
    value: {
      deep: true,
      handler () {
        if (!this.hasChanged) this.hasChanged = true
      },
    },
    definition () {
      if (this.hasChanged) this.hasChanged = false
    },
  },
})
