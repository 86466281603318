




































































import Vue, { PropOptions } from 'vue'
import { ValidationProvider } from 'vee-validate'
import { lckServices } from '@/services/lck-api'
import InputText from 'primevue/inputtext'
import Textarea from 'primevue/textarea'
import DialogForm from '@/components/ui/DialogForm/DialogForm.vue'
import { LckTable } from '@/services/lck-api/definitions'
import { createSlug } from '@/services/lck-utils/transformText'

export default Vue.extend({
  name: 'CreateTableModal',
  components: {
    'lck-dialog-form': DialogForm,
    'p-input-text': Vue.extend(InputText),
    'p-textarea': Vue.extend(Textarea),
    'validation-provider': Vue.extend(ValidationProvider),
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    } as PropOptions<boolean>,
    databaseId: {
      type: String,
      required: true,
    } as PropOptions<string>,
  },
  data () {
    return {
      name: null as string | null,
      documentation: null as string | null,
      errorMessage: null as string | null,
    }
  },
  computed: {
    autogenerateSlug (): null|string {
      if (this.name) return createSlug(this.name)
      return null
    },
  },
  methods: {
    closeCreateTableModal (reloadParent = false) {
      this.name = null
      this.documentation = null
      this.errorMessage = null
      this.$emit('close', reloadParent)
    },
    async confirmCreateTableModal () {
      try {
        const createTableResponse = await lckServices.table.create({
          // eslint-disable-next-line @typescript-eslint/camelcase
          database_id: this.databaseId,
          text: this.name,
          documentation: this.documentation,
          slug: this.autogenerateSlug,
        } as LckTable)
        if (createTableResponse) {
          this.closeCreateTableModal(true)
        }
      } catch (error) {
        if (error instanceof Error) this.errorMessage = error.message
      }
    },
  },
})
