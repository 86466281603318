




























































import Vue, { PropType } from 'vue'

import { LckBlockExtended, LckContainer } from '@/services/lck-api/definitions'

import Sidebar from 'primevue/sidebar'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'

import UpdateBlockForm from '@/components/visualize/UpdateSidebar/UpdateBlockForm/UpdateBlockForm.vue'
import UpdateContainerForm from '@/components/visualize/UpdateSidebar/UpdateContainerForm/UpdateContainerForm.vue'
import UpdatePageForm from '@/components/visualize/UpdateSidebar/UpdatePageForm/UpdatePageForm.vue'

export default Vue.extend({
  name: 'UpdateSidebar',
  components: {
    'update-block-form': UpdateBlockForm,
    'update-container-form': UpdateContainerForm,
    'update-page-form': UpdatePageForm,
    'p-sidebar': Vue.extend(Sidebar),
    'p-tab-view': Vue.extend(TabView),
    'p-tab-panel': Vue.extend(TabPanel),
  },
  props: {
    showSidebar: {
      type: Boolean,
      default: false,
    },
    container: {
      type: Object as PropType<LckContainer>,
      default: () => new LckContainer(),
    },
    block: {
      type: Object as PropType<LckBlockExtended>,
      default: () => new LckBlockExtended(),
    },
    page: {
      type: Object,
      default: () => ({}),
    },
    submitting: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '40rem',
    },
    autocompleteSuggestions: {
      type: Array as PropType<{ label: string; value: string }[]>,
      default: () => [],
    },
    blockDisplayTableViewSuggestions: {
      type: Array as PropType<{ label: string; value: string }[]>,
      default: () => [],
    },
    blockDisplayFieldSuggestions: {
      type: Array as PropType<{ label: string; value: string }[]>,
      default: () => [],
    },
    relatedChapterPages: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    activePanel (): boolean[] {
      // [Page, Container, Block]
      if (this.block?.id) {
        return [false, false, true]
      } else {
        if (this.container?.id) return [false, true, false]
        return [true, false, false]
      }
    },
  },
  methods: {
    onTabChange ({ index }: { index: number }) {
      if (index === 1) {
        this.$emit('reset-current-block', {})
      }
      if (index === 0) {
        this.$emit('reset-current-block', {})
        this.$emit('reset-current-container', {})
      }
    },
    resetSidebar () {
      this.$emit('reset-current-block', {})
    },
  },
})
