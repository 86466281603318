




























import Vue from 'vue'

import { ROUTES_NAMES } from '@/router/paths'
import LayoutWithHeader from '@/layouts/WithHeader.vue'

export default Vue.extend({
  name: 'Account',
  components: {
    'layout-with-header': Vue.extend(LayoutWithHeader),
  },
  computed: {
    menuItems () {
      return [{
        label: this.$t('pages.account.profile'),
        icon: 'bi-person',
        to: {
          name: ROUTES_NAMES.PROFILE.USER,
        },
      }, {
        label: this.$t('pages.account.groups'),
        icon: 'bi-people',
        to: {
          name: ROUTES_NAMES.PROFILE.GROUPS,
        },
      }]
    },
  },
})
