

















import Vue from 'vue'
import ProgressSpinner from 'primevue/progressspinner'
import { getAttachmentBlob } from '@/services/lck-api/helpers'

export default Vue.extend({
  name: 'LckAsyncImage',
  components: {
    'p-spinner': Vue.extend(ProgressSpinner),
  },
  props: {
    src: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
  },
  data () {
    return {
      loading: false,
      link: '',
      error: false,
    }
  },
  methods: {
    async getLink () {
      if (!this.src) return
      this.loading = true
      try {
        const blob = await getAttachmentBlob(this.src)
        this.link = URL.createObjectURL(blob)
      } catch {
        this.error = true
      }
      this.loading = false
    },
  },
  watch: {
    src: {
      handler () {
        this.getLink()
      },
      immediate: true,
    },
  },
})
