































































































































































































import Vue, { PropType } from 'vue'

import { ValidationProvider } from 'vee-validate'

import { NAMED_CLASSES } from '@/services/lck-utils/prime'
import { LckContainer } from '@/services/lck-api/definitions'

import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import InputSwitch from 'primevue/inputswitch'
import Dropdown from 'primevue/dropdown'

import LckForm from '@/components/ui/Form/Form.vue'

export default Vue.extend({
  name: 'UpdateContainerForm',
  components: {
    'lck-form': LckForm,
    'p-input-text': Vue.extend(InputText),
    'p-button': Vue.extend(Button),
    'p-datatable': Vue.extend(DataTable),
    'p-column': Vue.extend(Column),
    'p-switch': Vue.extend(InputSwitch),
    'p-dropdown': Vue.extend(Dropdown),
    'validation-provider': Vue.extend(ValidationProvider),
  },
  props: {
    container: {
      type: Object as PropType<LckContainer>,
      required: true,
    },
    submitting: {
      type: Boolean,
      default: false,
    },
    autocompleteSuggestions: {
      type: Array as PropType<{ label: string; value: string }[]>,
      default: () => [],
    },
  },
  data () {
    return {
      containerCopy: new LckContainer(),
      NAMED_CLASSES,
    }
  },
  methods: {
    onFormSubmit () {
      // Remove invariant data
      // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/camelcase
      const { blocks, createdAt, page_id, updatedAt, ...data } = this.containerCopy

      if (this.containerCopy?.displayed_in_navbar) {
        return this.$emit('update-container', data)
      }
      // Don't send default label for anchor_label if no anchor
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/camelcase
      const { anchor_label, ...rest } = data
      return this.$emit('update-container', rest)
    },
  },
  watch: {
    container: {
      handler (newValue = {}) {
        this.containerCopy = {
          ...newValue,
          // eslint-disable-next-line @typescript-eslint/camelcase
          anchor_label: newValue.anchor_label || newValue.text,
          // eslint-disable-next-line @typescript-eslint/camelcase
          displayed_in_navbar: newValue.displayed_in_navbar || false,
          // eslint-disable-next-line @typescript-eslint/camelcase
          anchor_icon_class: newValue.anchor_icon_class || 'primary',
        }
      },
      immediate: true,
      deep: true,
    },
  },
})
