













































































































































































































































































































import Vue, { PropOptions } from 'vue'

import { ACTION_BUTTON_TYPE } from '@locokit/lck-glossary'
import { ValidationProvider } from 'vee-validate'

import {
  ACTIONS_TYPE,
  NAMED_CLASSES,
} from '@/services/lck-utils/prime'
import { LckTableAction, LckTableColumn } from '@/services/lck-api/definitions'
import { ROUTES_NAMES } from '@/router/paths'

import InputText from 'primevue/inputtext'
import Dropdown from 'primevue/dropdown'
import Panel from 'primevue/panel'
import RadioButton from 'primevue/radiobutton'

import LckForm from '@/components/ui/Form/Form.vue'
import AutoComplete from '@/components/ui/AutoComplete/AutoComplete.vue'
import { lckServices } from '@/services/lck-api'
import { getPageWithChapters } from '@/services/lck-api/helpers'

export default Vue.extend({
  name: 'ActionColumnForm',
  components: {
    'lck-form': LckForm,
    'lck-autocomplete': AutoComplete,
    'p-input-text': Vue.extend(InputText),
    'p-radio-button': Vue.extend(RadioButton),
    'p-panel': Vue.extend(Panel),
    'p-dropdown': Vue.extend(Dropdown),
    'validation-provider': Vue.extend(ValidationProvider),
  },
  props: {
    action: {
      type: Object,
      default: () => ({}),
    },
    submitting: {
      type: Boolean,
      default: false,
    },
    manualProcesses: {
      type: Array,
      default: () => ([]),
    } as PropOptions<Array<{id: string; text: string}>>,
    autocompleteSuggestions: {
      type: Array,
      default: () => ([]),
    } as PropOptions<{ label: string; value: string }[]>,
  },
  data (): {
    ROUTES_NAMES: typeof ROUTES_NAMES;
    NAMED_CLASSES: typeof NAMED_CLASSES;
    ACTIONS_TYPE: typeof ACTIONS_TYPE;
    ACTION_BUTTON_TYPE: typeof ACTION_BUTTON_TYPE;
    actionCopy: LckTableAction | null;
    columnActionCondition: LckTableColumn | null;
    page: { text: string; value: string } | null;
    processes: [];
    } {
    return {
      ROUTES_NAMES,
      NAMED_CLASSES,
      ACTIONS_TYPE,
      ACTION_BUTTON_TYPE,
      actionCopy: null,
      columnActionCondition: null,
      page: null,
      processes: [],
    }
  },
  computed: {
    transformProcesses (): Array<{text: string; value: string}> {
      return this.manualProcesses.map(process => ({
        text: process.text,
        value: process.id,
      }))
    },
  },
  methods: {
    submitActionColumnData () {
      this.$emit('action-column-edit', this.actionCopy)
    },
    getColumns (columnId: string): Promise<LckTableColumn> {
      return lckServices.tableColumn.get(columnId)
    },
    getSuggestionPage (query: string) {
      if (
        // TODO: I think this is legacy code as I didn't find any data in type_page_to
        // this.actionCopy?.typePageTo === ROUTES_NAMES.PAGEDETAIL ||
        this.actionCopy?.action === ACTION_BUTTON_TYPE.PAGE_DETAIL_TO
      ) {
        this.$emit('search-page', { query: query, filters: { hidden: true } })
      } else {
        this.$emit('search-page', { query: query })
      }
    },
  },
  watch: {
    action: {
      async handler (newActionColumnValue: LckTableAction) {
        this.actionCopy = { ...newActionColumnValue }
        if (newActionColumnValue.action === ACTION_BUTTON_TYPE.PROCESS_TRIGGER) {
          const res = await getPageWithChapters(newActionColumnValue.pageRedirectId as string)
          this.page = {
            text: `[${res?.chapter?.text}] ${res.text}`,
            value: res.id,
          }
        } else {
          const res = await getPageWithChapters(newActionColumnValue.pageDetailId as string)
          this.page = {
            text: `[${res?.chapter?.text}] ${res.text}`,
            value: res.id,
          }
        }
        if (newActionColumnValue.displayFieldId) {
          this.columnActionCondition = await this.getColumns(newActionColumnValue.displayFieldId)
        }
      },
      immediate: true,
    },
  },
})
