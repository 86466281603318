var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"blockSettings"},[_c('validation-provider',{staticClass:"p-field",attrs:{"vid":"blockSettingsDisplayMode","tag":"div","name":_vm.$t('pages.workspace.block.displayMode'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
    var classes = ref.classes;
return [_c('label',{staticClass:"label-field-required",attrs:{"for":"blockSettingsDisplayMode"}},[_vm._v(_vm._s(_vm.$t('pages.workspace.block.displayMode')))]),_c('p-dropdown',{attrs:{"id":"blockSettingsDisplayMode","options":_vm.mediaTypes,"value":_vm.displayMode,"placeholder":_vm.$t('components.datatable.placeholder'),"optionValue":"value","optionLabel":"label","optionKey":"value"},on:{"input":function($event){return _vm.$emit('update:displayMode', $event)}}}),_c('span',{class:classes},[_vm._v(_vm._s(errors[0]))])]}}])}),(_vm.displayMode)?_c('div',[_vm._l((_vm.medias),function(media,index){return _c('div',{key:("media-" + index),staticClass:"mediaConfiguration",class:{ multiMediaConfiguration: !_vm.isBasicMedia }},[_c('validation-provider',{staticClass:"p-field",attrs:{"vid":("blockSettingsMediaName" + index),"tag":"div","name":_vm.$t('pages.workspace.block.mediaName'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
        var classes = ref.classes;
return [_c('label',{staticClass:"label-field-required",attrs:{"for":("blockSettingsMediaName" + index)}},[_vm._v(_vm._s(_vm.$t('pages.workspace.block.mediaName')))]),_c('p-input-text',{attrs:{"id":("blockSettingsMediaName" + index),"value":media.name},on:{"input":function($event){return _vm.$emit('update-media-name', { media: media, name: $event })}}}),_c('span',{class:classes},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validation-provider',{staticClass:"p-field",attrs:{"vid":("blockSettingsMediaSrcURL" + index),"tag":"div","name":_vm.$t('pages.workspace.block.mediaSrcURL'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
        var classes = ref.classes;
return [_c('label',{staticClass:"label-field-required",attrs:{"for":("blockSettingsMediaSrcURL" + index)}},[_vm._v(_vm._s(_vm.$t('pages.workspace.block.mediaSrcURL')))]),_c('p-input-text',{attrs:{"id":("blockSettingsMediaSrcURL" + index),"value":media.srcURL},on:{"input":function($event){return _vm.$emit('update-media-srcURL', { media: media, srcURL: $event })}}}),_c('span',{class:classes},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('validation-provider',{staticClass:"p-field",attrs:{"vid":("blockSettingsMediaName" + index),"tag":"div","name":_vm.$t('pages.workspace.block.mediaType'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
        var classes = ref.classes;
return [_c('label',{staticClass:"label-field-required",attrs:{"for":("blockSettingsMediaName" + index)}},[_vm._v(_vm._s(_vm.$t('pages.workspace.block.mediaType')))]),_c('p-dropdown',{attrs:{"id":("blockSettingsMediaName" + index),"options":_vm.basicMediaTypes,"value":media.type,"placeholder":_vm.$t('components.datatable.placeholder'),"optionValue":"value","optionLabel":"label","optionKey":"value"},on:{"input":function($event){return _vm.$emit('update-media-type', { media: media, type: $event })}}}),_c('span',{class:classes},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('p-button',{staticClass:"p-button-text p-button-lg deleteMediaButton",attrs:{"icon":"bi bi-trash"},on:{"click":function($event){return _vm.$emit('delete-media', index)}}})],1)}),(!_vm.isBasicMedia || _vm.medias.length === 0)?_c('div',{staticClass:"addMediaDiv"},[_c('p-button',{staticClass:"p-button-text addMediaButton p-button-lg",attrs:{"icon":"pi pi-plus"},on:{"click":function($event){return _vm.$emit('add-media')}}})],1):_vm._e()],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }